import Vue from 'vue'
import Router from 'vue-router'

import Home from '@/components/Home'
import Profile from '@/components/user/Profile'
import Deposit from '@/components/payment/Deposit'
import Withdraw from '@/components/payment/Withdraw'
import Bonuses from '@/components/Bonuses'
import TheGame from '@/components/games/TheGame'
import ThePoker from '@/components/games/ThePoker'
import Terms from '@/components/Terms'
import UserLevels from "@/components/UserLevels";
Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            name: 'home',
            path: '/',
            props: true,
            component: Home
        },
        {
            name: 'profile',
            path: '/profile/:placeName',
            component: Profile,
            props: true
        },
        {
            name: 'deposit',
            path: '/deposit',
            component: Deposit
        },
        {
            name: 'withdraw',
            path: '/withdraw',
            component: Withdraw
        },
        {
            name: 'bonuses',
            path: '/bonuses',
            component: Bonuses
        },
        {
            name: 'TheGame',
            path: '/game/:gameName',
            props: true,
            component: TheGame
        },
        {
            name: 'ThePoker',
            path: '/Poker',
            props: true,
            component: ThePoker
        },
        {
            name: 'terms',
            path: '/terms',
            component: Terms
        },
        {
            name: 'UserLevels',
            path: '/Levels',
            props: true,
            component: UserLevels,
            meta: {
                title: 'Уровни игроков'
            }
        }
    ]
})

