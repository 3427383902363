<template>
    <div class="game-load-block main-bg-light row mx-0 py-5">
        <div class="col-12 mx-auto mt-2 mt-md-5 mt-lg-0">
            <div class="row w-100 mx-0 pb-0 pb-lg-5 mt-0 mt-md-0">
                <div class="col-11 mx-auto px-0 game-cross-block">
                    <div class="px-0 game-response-container text-center" id="game-response-container">
                        <iframe class="gameLaunchIframe  mx-auto"
                                :src="gameLaunchUrl()"
                                allowfullscreen
                                frameborder="0"
                                scrolling="no"
                                marginheight="0">
                        </iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  /*eslint no-debugger: "error"*/
  import {scroller} from 'vue-scrollto/src/scrollTo'
  import Config from '@/config'

  const secondScrollTo = scroller()

  export default {
    name: 'TheGame',
    props: ['game', 'provider', 'gameName', 'gameId', 'restoring', 'isLive'],
    data() {
      return {
        isLandscape: false,
        response: {status: 'loading'},
        loaded: false
      }
    },
    watch: {
      isLandscape() {
        this.isLandscape = screen.height < screen.width
      },
      restoring() {
        if (this.restoring) {
          this.$bvModal.hide('HasAliveGame')
        }
      }
    },
    computed: {
      userData() {
        return this.$store.getters['userData']
      },
      isMobile() {
        return this.$_config.environment.mobile
      }
    },
    methods: {
      gameLaunchUrl() {
        return Config.baseUrl + '/Api/LoadGame/Poker/'
      },
      containerSize() {
        try {
          let availWidth = document.querySelector('.game-cross-block').offsetWidth
          return {
            width: availWidth,
            height: availWidth / 1.8
          }
        } catch (err) {
          console.log(err.message)
        }
      },
      setOriantationOnChangeEvent() {
        window.addEventListener('resize', () => {
          setTimeout(() => {
            this.isLandscape = window.innerWidth > window.innerHeight
          }, 20)
        })
      }
      },
    mounted() {
      if (this.isMobile) {
        window.location.href = this.gameLaunchUrl()
        return false
      }
      if (!this.userData) {
        this.$router.go(-1)
        this.$root.$emit('bv::show::modal', 'login-modal')
        return
      }
      this.setOriantationOnChangeEvent();
      if (document.querySelector('.gameLaunchIframe')) {
        document.querySelector('.gameLaunchIframe').onload = () => {
          this.loaded = true
        }
      }

      setTimeout(() => {
        secondScrollTo('iframe')
      }, 600)
    }
  }
</script>

<style scoped>
    .game-load-block {
        background-image: url("../../../public/images/bg.png");
        min-height: fit-content !important;
        background-repeat: no-repeat;
        height: fit-content;
    }
    .gameLaunchIframe {
        width: 100%;
        min-height: 100vh;
    }
</style>
