<template>
    <div class="terms-container main-bg-light row w-100 mx-0 py-5" v-html="terms" v-if="terms"></div>
    <div class="terms-container main-bg-light row w-100 mx-0 py-5" v-else>
        <div class="row w-100 mx-0 justify-content-center">
            <i class="fa fa-spinner fa-spin fa-fw my-5 text-white"></i>
        </div>
    </div>
</template>

<script>
  export default {
    computed: {
      terms() {
        return this.$store.getters['getTerms']
      }
    }
  }
</script>

