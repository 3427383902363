<template>
    <b-modal id="confirmEmailModal" size="md"
             :hide-footer="true"
             :no-close-on-esc="true"
             :no-close-on-backdrop="true"
             v-if="userData"
             @close="closeModal"
    >
        <div class="modal-title text-center mb-3">
            <h3 class="text-white">Подтверждение email</h3>
            <span class="text-white">{{userData.email}}</span>
        </div>
        <form @submit.prevent="confirmEmail" class="mt-4" autocomplete="off" v-if="!successedVerification">
            <Alert :message="emailSendResponse"></Alert>
            <b-form-group>
                <div class="row mx-0">
                    <div class="col-4 col-sm-3 px-0">
                        <captcha ref="email_confirmation" :captcha_key="'email_confirmation'"></captcha>
                    </div>
                    <div class="col-8 col-lg-9 pr-0">
                        <b-form-input v-model="captcha" required class="input-bg input-bg-dot-auth"></b-form-input>
                    </div>
                </div>
                <div class="row mt-4 mx-0">
                    <button name="submit" type="submit" class="btn red-btn px-5 mx-auto" :disabled="isEmailSending"
                            @click.stop.prevent="sendConfirmationEmail">
                        Отправить письмо
                    </button>
                </div>
            </b-form-group>
        </form>
        <div class="row mx-0 my-5" v-else>
            <span class="mx-auto text-white text-center">
               Перейдите по ссылке из письма, которое мы отправили на ваш email
            </span>
        </div>
    </b-modal>
</template>

<script>
    import captcha from '@/components/Captcha'
    import Alert from '@/components/Alert'

    export default {
        props: ['userData'],
        name: 'ConfirmEmail',
        data() {
            return {
                successedVerification: false,
                emailSendResponse: null,
                isEmailSending: false,
                captcha: ''
            }
        },
        components: {
            Alert,
            captcha
        },
        methods: {
            closeModal() {
                this.emailSendResponse = null
            },
            sendConfirmationEmail() {
                this.emailSendResponse = {status: 'loading'}
                this.isEmailSending = true
                let queryString = `email=${this.userData.email}&email_confirmation_captcha=${this.captcha}`
                this.axios.post(
                    this.$_config.baseUrl + '/Api/EmailConfirmRequest/', queryString,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(({data}) => {
                    this.emailSendResponse = data
                    this.captcha = ''
                    if (data.status !== "error") {
                        this.$store.dispatch('fetchUserData')
                        this.successedVerification = true
                        setTimeout(() => {
                            this.successedVerification = false
                            this.emailSendResponse = null
                            this.$root.$emit('bv::hide::modal', 'confirmEmailModal')
                        }, 5000)
                    }
                }).catch(() => {
                    this.emailSendResponse = {
                        status: 'error', msg: 'Ошибка при отправке запроса, повторите позже'
                    }
                })
                this.isEmailSending = false
                this.$refs.email_confirmation.updateCaptcha()
            }
        },
    }
</script>

<style scoped>
    @media only screen and (max-width: 767px) {
        h3 {
            font-size: 1.2rem;
        }
    }
</style>
