<template>
    <div class="bonus-container main-bg-light row w-100 mx-0 py-5">
        <div class="col-11 px-0 mx-auto pt-1 pt-md-5 pt-lg-3 pt-xl-0"
             v-lazy-container="{ selector: 'img',  loading: require('@/../public/images/icons/game-images-loader.svg') }">
            <h2 class="text-white mb-4">Бонусы</h2>
            <div class="row w-100 mx-0" v-if="bonuses">
                <div class="col-12 col-xl-6 p-0 pr-xl-2 pl-xl-0" v-for="(bonus, idx) in bonuses" :key="idx">
                    <div class="row bonus-item main-bg mx-0 px-0 px-lg-2 py-4 mb-3">

                        <div class="row mx-0 w-100 bonus-desc">
                            <div class="col-12 col-md-3 mb-3 mb-md-0 align-self-center text-center">
                                <img :src="getIcon(bonus.icon)" :data-src="getIcon(bonus.icon)" :alt="bonus.title"
                                     class="bonus-img mw-100">
                            </div>
                            <div class="col-12 col-md-9 align-self-center">
                                <h6 class="text-white text-uppercase">{{bonus.title}}</h6>
                                <div v-html="bonus.text"></div>
                            </div>
                        </div>

                        <div class="row w-100 h-100 mx-0 mt-3 mt-md-0 align-self-end">
                            <div class="col-0 col-md-3"></div>
                            <div class="col-12 col-md-9 text-center text-md-left mt-3">
                                <a class="btn red-btn px-5" v-if="bonus.buttonText && !bonus.buttonHref"> {{bonus.buttonText}}</a>
                                <a class="btn red-btn px-5" v-else-if="bonus.buttonText && bonus.buttonHref" target="_blank" :href="bonus.buttonHref"> {{bonus.buttonText}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 justify-content-center" v-else>
                <i class="fa fa-spinner fa-spin fa-fw my-5 text-white"></i>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    computed: {
      bonuses() {
        return this.$store.getters['getBonuses']
      }
    },
    methods: {
      getIcon(icon) {
        return require('@/../public/images/bonuses/' + icon)
      }
    }
  }
</script>

<style scoped>
    .red-btn {
        box-shadow: 0 0 25px 0 #e5001b !important;
        cursor: initial;
    }

    .bonus-img {
        transition: 1s;
    }

    .bonus-img:hover {
        transform: rotate(15deg);
    }

    .bonus-desc {
        min-height: 370px;
    }

    .bonus-desc-small {
        min-height: 190px;
    }

    @media (min-width: 768px) and (max-width: 1199px) {
        .bonus-desc {
            min-height: 250px;
        }
    }

    @media (min-width: 1360px) {
        .bonus-desc {
            min-height: 320px;
        }
    }
</style>
