<template>
    <b-modal :hide-footer="true"
             :no-close-on-backdrop="true"
             :no-close-on-esc="true"
             id="recover-modal"
    >
        <div class="row mx-0">
            <h3 class="text-white mx-auto text-center">Восстановить пароль</h3>
            <div class="row w-100 my-3 mx-0 justify-content-center">
                <b-tabs class="tab pass-recover-tab px-0 w-100">
                    <b-tab class="tab-item" active title="Почта">
                        <recover-by-email></recover-by-email>
                    </b-tab>
                    <b-tab class="tab-item" title="Телефон" v-if="siteSettings && siteSettings.phoneActionsMethod === 'call'">
                        <RecoverByCall></RecoverByCall>
                    </b-tab>
                    <b-tab class="tab-item" title="Через SMS" v-else>
                        <recover-by-sms></recover-by-sms>
                    </b-tab>
                </b-tabs>
            </div>
        </div>

    </b-modal>
</template>

<script>
    import RecoverByEmail from '@/components/user/passRecover/RecoverViaEmail'
    import RecoverBySms from '@/components/user/passRecover/RecoverViaSms'
    import RecoverByCall from '@/components/user/passRecover/RecoverViaCall'

    export default {
        name: 'recoverPass',
        components: {
            RecoverByEmail,
            RecoverBySms,
            RecoverByCall
        },
        computed: {
            userData() {
                return this.$store.getters['userData']
            },
            siteSettings () {
                return this.$store.getters.getMainSettings
            },
        },
        watch: {
            userData(newValue) {
                if (newValue) {
                    this.$root.$emit('bv::hide::modal', 'recover-modal')
                }
            }
        },
        mounted() {
            if (this.userData) {
                this.$root.$emit('bv::hide::modal', 'recover-modal')
            }
        }
    }
</script>

<style>
    .pass-recover-tab .nav-tabs {
        border: none !important;
        justify-content: center;
        font-weight: bold;
        overflow: hidden;
    }

    .pass-recover-tab .nav-tabs .nav-item .nav-link {
        border: none;
        outline: none;
        color: white;
        margin-right: 15px;
        padding-left: 0 !important;
        border-bottom: 2px solid white !important;
    }

    .pass-recover-tab .nav-tabs .nav-item .nav-link.active {
        color: #a4252e;
        background-color: transparent;
        border-bottom: 2px solid #a4252e !important;
    }

    .tab-pane {
        outline: none;
    }
</style>

<style scoped>
    @media only screen and (max-width: 767px) {
        h3 {
            font-size: 1.65rem;
        }
    }
</style>
