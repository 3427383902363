<template>
    <div class="row mx-0 py-5 justify-content-center features-container">
        <div v-for="(feature, idx) in feaures" :key="idx"
             class="col-11 col-sm-6 col-md-4 col-xl-3 mt-2 mt-lg-0 mb-3 mb-lg-0 text-center feature"
             v-lazy-container="{ selector: 'img',  loading: require('@/../public/images/icons/game-images-loader.svg') }">

            <img :src="feature.img" :data-src="feature.img" :alt="feature" class="feature-img mb-0 mb-lg-3"><br>
            <span class="text-white font-weight-bold">{{feature.title}}</span><br>
            <span class="text-orange font-weight-bold">{{feature.text}}</span>

        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                feaures: [
                    {
                        img: require('@/../public/images/main-features/big-win.png'),
                        title: 'Большой выигрыш',
                        text: 'Выигрыш каждый день'
                    },
                    {
                        img: require('@/../public/images/main-features/interesting-games.png'),
                        title: 'Самые интересные игры',
                        text: 'Большой выбор'
                    },
                    {
                        img: require('@/../public/images/main-features/withdraw.png'),
                        title: 'Круглосуточное снятие',
                        text: 'Без комиссии'
                    }
                ]
            }
        }
    }
</script>

<style scoped>
    .features-container {
        background-color: #001a4c;
    }

    .feature-img {
        height: 220px;
        /*width: 300px;*/
        object-fit: contain;
    }
    @media only screen and (max-width: 1200px) {
        .feature-img {
            height: 180px;
        }
    }
</style>
