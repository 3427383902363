<template>
    <div class="row recover-by-sms-container mx-0">
        <b-form class="auth-form w-100 my-3 px-0" @submit.prevent="Restore" autocomplete="off" v-if="!showSuccess">
            <b-row class="w-100 mx-auto">
                <alert :message="response"></alert>
            </b-row>
            <div class="row mx-0">
                <the-mask :mask="['+#(###)-###-##-##', '+#(###)-###-##-###']" class="form-control input-bg input-bg-phone" v-model="form.phone"
                          aria-label="phone-number" required type="text" placeholder="Номер телефона"></the-mask>
            </div>
            <div class="row mx-0 my-4" v-if="!show">
                <div class="col-4 col-sm-3 px-0">
                    <captcha ref="password_change_sms" :captcha_key="'password_change_sms'"></captcha>
                </div>
                <div class="col-8 col-sm-9 pr-0">
                    <b-form-input v-model="form.captcha" required aria-label="captcha"
                                  class="input-bg input-bg-dot-auth"></b-form-input>
                </div>
                <div class="col-12 mt-4 mx-0 text-center">
                    <button class="btn red-btn px-5" type="submit" :disabled="isSubmitting">
                        Звонить на номер
                    </button>
                </div>
            </div>

            <div v-else>
                <div class="row mx-0 mt-4">
                    <div class="col-12 mb-3 px-0">
                        <b-form-input v-model="form.call_code" type="text" required aria-label="call-code"
                                      placeholder="Последние 4 цифры номера  с которого поступил звонок"
                                      class="input-bg input-bg-dot-auth"></b-form-input>
                    </div>
                    <div class="col-12 col-sm-6 my-2 pl-0 pr-0 pr-sm-2">
                        <b-form-input v-model="form.new_password" required type="password" placeholder="Пароль *"
                                      aria-label="password"></b-form-input>
                    </div>
                    <div class="col-12 col-sm-6 my-2 px-0 pl-sm-2">
                        <b-form-input v-model="form.new_password_confirm" type="password" required
                                      placeholder="Пароль еще раз *" aria-label="re-password"></b-form-input>
                    </div>
                </div>
                <div class="row mx-0 my-4">
                    <div class="col-4 col-sm-3 px-0">
                        <captcha ref="password_change_sms" :captcha_key="'password_change_sms'"></captcha>
                    </div>
                    <div class="col-8 col-sm-9 pr-0">
                        <b-form-input v-model="form.captcha" required class="input-bg input-bg-dot-auth"
                                      aria-label="captcha"></b-form-input>
                    </div>
                </div>

                <div class="row mx-0">
                    <button class="mx-auto btn red-btn px-5" type="submit" :disabled="isSubmitting">
                      Восстановить
                    </button>
                </div>

            </div>
        </b-form>
        <div class="row w-100 mx-0 my-3" v-else>
            <h2 class="text-white w-100 my-3 text-center">Отлично!</h2>
            <span class="text-white mx-auto">Ваш пароль успешно изменён</span>
        </div>
    </div>
</template>

<script>
    import Captcha from '@/components/Captcha'
    import Alert from '@/components/Alert'

    export default {
        data() {
            return {
                response: null,
                isSubmitting: false,
                form: {
                    phone: '',
                    captcha: '',
                    call_code: '',
                    new_password: '',
                    new_password_confirm: ''
                },
                show: false,
                showSuccess: false
            }
        },
        components: {
            Captcha,
            Alert
        },
        methods: {
            Restore() {
                this.response = {status: 'loading'}
                this.isSubmitting = true

                if (this.show) {
                    let sendPayload = {
                        phone: this.form.phone,
                        call_code: this.form.call_code,
                        new_password: this.form.new_password,
                        password_restore_captcha: this.form.captcha,
                        new_password_confirm: this.form.new_password_confirm
                    }

                    let queryString = this.$_config.makeQueryStringFromObject(sendPayload)
                    this.axios.post(
                        this.$_config.baseUrl + '/Api/restorePasswordAfterCall', queryString,
                        {
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            }
                        }
                    ).then(({data}) => {
                        this.isSubmitting = false
                        this.response = data
                        this.form.captcha = ''
                        this.$refs.password_change_sms.updateCaptcha()
                        if (data.status === 'ok') {
                            this.show = false
                            this.showSuccess = true
                            setTimeout(() => {
                                this.showSuccess = false
                                this.$root.$emit('bv::hide::modal', 'recover-modal')
                                this.$root.$emit('bv::show::modal', 'login-modal')
                            }, 7000)
                        }
                    }).catch((err) => {
                        if (err) {
                            this.isSubmitting = false
                            this.form.captcha = ''
                            this.$refs.password_change_sms.updateCaptcha()
                            this.response = {status: 'error', msg: err.response.data.errors}
                        }
                    })
                } else {
                    this.axios.post(
                        this.$_config.baseUrl + '/Api/callForRestorePassword',
                        `phone=${this.form.phone}&captcha=${this.form.captcha}`,
                        {
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            }
                        }
                    ).then(({data}) => {
                        this.isSubmitting = false
                        this.response = data
                        if (data.status === 'ok') {
                            this.show = true
                        }
                        this.form.captcha = ''
                        this.$refs.password_change_sms.updateCaptcha()
                    }).catch((err) => {
                        if (err) {
                            this.isSubmitting = false
                            this.$refs.password_change_sms.updateCaptcha()
                            this.form.captcha = ''
                            this.response = {status: 'error', msg: err.response.data.errors}
                        }
                    })
                }
            }
        }
    }
</script>
