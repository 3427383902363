<template>
    <div class="row mx-0 w-100 navbar-container">
        <div class="col-12 px-0 px-xl-4 mx-auto">

            <div class="mobile-logo d-md-none">
                <router-link :to="{ name: 'home' }" aria-label="home">
                    <img class="header-logo px-1" :src="logo" alt="SegaSlot">
                </router-link>
            </div>

            <div class="row mx-0">
                <div class="col-12 px-0 main-container">
                    <b-navbar toggleable="md" type="dark" variant="custom" class="main-menu">

                        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                        <b-collapse id="nav-collapse" is-nav>
                            <!--logo for desctops-->
                            <div class="col-0 col-md-6 col-xl-2 px-0 h-100">
                                <b-navbar-brand class="mx-0 px-0 d-none d-md-block">
                                    <router-link :to="{ name: 'home' }">
                                        <img class="header-logo px-1" :src="logo" alt="SegaSlot">
                                    </router-link>
                                </b-navbar-brand>
                            </div>

                            <!--menu list-->
                            <div class="px-0 menu-list"
                                 :class="[largeMenuPage ? 'col-lg-12 col-lg-9 col-xl-6 pt-lg-2 pt-xl-0' : 'col-lg-9 col-xl-6 pt-0']">

                                <b-navbar-nav class="justify-content-around main-manu pb-3 pb-xl-0 h-100 ">

                                    <b-nav-item class="pl-5 pl-md-0 h-100 mt-1 mt-md-0">
                                        <router-link :to="{ name: 'home' }" active-class="active-page" exact
                                                     class="px-0 mx-0 menu-item">
                                            <img class="icon mr-2 mr-md-0 mr-xl-2" :src="icons.home" alt="Казино">
                                            <span class="text-white d-xl-inline ml-1"
                                                  :class="[largeMenuPage ? 'd-inline ml-lg-2 ml-lg-1' : 'ml-md-0 mx-md-auto d-md-block']">Казино</span>
                                        </router-link>
                                    </b-nav-item>

                                    <b-nav-item class="pl-5 pl-md-0 h-100 mt-1 mt-md-0">
                                        <router-link
                                                :to="{ name: 'TheGame', params: {gameName:'KronosWheel', gameId:1, provider: 'WheelOfFortune'}}"
                                                active-class="active-page" exact class="px-0 mx-0 menu-item">
                                            <img class="icon mr-2 mr-md-0 mr-xl-2 fa-spin" :src="icons.wheel" alt="Колесо">
                                            <span class="text-white d-xl-inline ml-1"
                                                  :class="[largeMenuPage ? 'd-inline ml-lg-2 ml-xl-1' : 'ml-md-0 mx-md-auto d-md-block']">Колесо</span>
                                        </router-link>
                                    </b-nav-item>

                                    <b-nav-item class="pl-5 pl-md-0 h-100 mt-1 mt-md-0">
                                        <router-link :to="{ name: 'deposit' }" active-class="active-page" exact
                                                     class="px-0 mx-0 menu-item">
                                            <img class="icon mr-2 mr-md-0 mr-xl-2" :src="icons.deposit" alt="Депозит">
                                            <span class="text-white d-xl-inline ml-1"
                                                  :class="[largeMenuPage ? 'd-inline ml-lg-2 ml-xl-1' : 'ml-md-0 mx-md-auto d-md-block']">Депозит</span>
                                        </router-link>
                                    </b-nav-item>
                                    <b-nav-item class="pl-5 pl-md-0 h-100 mt-1 mt-md-0">
                                        <router-link :to="{ name: 'withdraw' }" active-class="active-page" exact
                                                     class="px-0 mx-0 menu-item">
                                            <img class="icon mr-2 mr-md-0 mr-xl-2" :src="icons.withdraw" alt="Выплаты">
                                            <span class="text-white d-xl-inline ml-1"
                                                  :class="[largeMenuPage ? 'd-inline ml-lg-2 ml-xl-1' : 'ml-md-0 mx-md-auto d-md-block']">Выплаты</span>
                                        </router-link>
                                    </b-nav-item>

                                    <b-nav-item class="pl-5 pl-md-0 h-100 mt-1 mt-md-0">
                                        <router-link :to="{ name: 'bonuses' }" active-class="active-page" exact
                                                     class="px-0 mx-0 menu-item">
                                            <img class="icon mr-2 mr-md-0 mr-xl-2" :src="icons.bonus" alt="Бонусы">
                                            <span class="text-white d-xl-inline ml-1"
                                                  :class="[largeMenuPage ? 'd-inline ml-lg-2 ml-xl-1' : 'ml-md-0 mx-md-auto d-md-block']">Бонусы</span>
                                        </router-link>
                                    </b-nav-item>

                                    <b-nav-item class="pl-5 pl-md-0 h-100 mt-1 mt-md-0">
                                        <router-link :to="{ name: 'terms' }" active-class="active-page" exact
                                                     class="px-0 mx-0 menu-item">
                                            <img class="icon mr-2 mr-md-0 mr-xl-2" :src="icons.terms" alt="Условия">
                                            <span class="text-white d-xl-inline ml-1"
                                                  :class="[largeMenuPage ? 'd-inline ml-lg-2 ml-xl-1' : 'ml-md-0 mx-md-auto d-md-block']">Условия</span>
                                        </router-link>
                                    </b-nav-item>
                                  <b-nav-item class="pl-5 pl-md-0 h-100 mt-1 mt-md-0">
                                    <router-link :to="{ name: 'UserLevels' }" active-class="active-page" exact
                                                 class="px-0 mx-0 menu-item">
                                      <img class="icon mr-2 mr-md-0 mr-xl-2" :src="icons.level" alt="Уровни клиентов">
                                      <span class="text-white d-xl-inline ml-1"
                                            :class="[largeMenuPage ? 'd-inline ml-lg-2 ml-xl-1' : 'ml-md-0 mx-md-auto d-md-block']">Уровни</span>
                                    </router-link>
                                  </b-nav-item>
                                    <!--mobile auth buttons-->
                                    <b-navbar-nav v-if="userDataFetched && !userData"
                                                  class="d-md-none px-2 my-4 my-sm-1 mobile-auth-btns  w-100">
                                        <div class="row mx-0">
                                            <div class="col-4 px-0">
                                                <b-nav-item class="text-right mr-3 mr-sm-5">
                                                    <a class="btn text-white login-btn px-0 pb-0"
                                                       @click="showLoginModal">
                                                        Войти</a>
                                                </b-nav-item>
                                            </div>
                                            <div class="col-8 col-sm-7 px-0">
                                                <b-nav-item class="px-2 register-item">
                                                    <a class="btn red-btn pulse px-4 w-100" @click="showRegModal">
                                                        Регистрация</a>
                                                </b-nav-item>
                                            </div>
                                        </div>
                                    </b-navbar-nav>

                                    <b-navbar-nav v-if="userDataFetched && userData"
                                                  class="my-3 d-md-none mobile-auth-btns w-100">
                                        <div class="auth-btns row mx-0 w-100 justify-content-start pl-5 ">
                                            <b-dropdown>
                                                <template v-slot:button-content>
                                                    <span> {{userData.login}} </span><br>
                                                    <span class="text-orange font-weight-bold">
                                                      (Баланс: {{userData.balance}} {{userData.currency_name}})
                                                    </span>
                                                  <br>
                                                </template>
                                                <b-dropdown-item>
                                                    <router-link :to="{ name: 'profile', params :{placeName: 'account'} }"> Профиль</router-link>
                                                </b-dropdown-item>
                                                <b-dropdown-item @click.prevent="logout">
                                                    <a href="#"> Выйти</a>
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </b-navbar-nav>
                                </b-navbar-nav>
                            </div>

                            <!--auth buttons area-->
                            <div class="col-6 col-lg-4 col-xl-4 px-0 d-none d-md-block" v-if="userDataFetched">
                                <b-navbar-nav v-if="!userData">
                                    <li class="w-100">
                                        <div class="auth-btns row mx-0 w-100 justify-content-md-end">
                                            <div class="col-md-4 px-0 text-center">
                                                <a class="btn text-white login-btn px-0 pb-0" @click="showLoginModal">
                                                    Войти</a>
                                            </div>
                                            <div class="col-8 px-0 pl-2">
                                                <a class="btn red-btn pulse px-4 w-100 text-center"
                                                   @click="showRegModal">
                                                    Регистрация</a>
                                            </div>
                                        </div>
                                    </li>
                                </b-navbar-nav>

                                <b-navbar-nav v-else>
                                    <li class="w-100">
                                        <b-dropdown class="float-right">
                                            <template v-slot:button-content>
                                                <span> {{userData.login}} </span>
                                                <span class="text-orange font-weight-bold">
                                                  (Баланс:{{userData.balance}} {{userData.currency_name}})
                                                </span>
                                            </template>
                                            <b-dropdown-item>
                                                <router-link :to="{ name: 'profile', params: { placeName: 'account' } }"> Профиль</router-link>
                                            </b-dropdown-item>
                                            <b-dropdown-item @click.prevent="logout">
                                                <a href="#"> Выйти</a>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </li>
                                </b-navbar-nav>
                            </div>
                        </b-collapse>
                    </b-navbar>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'RwvHeader',
        data() {
            return {
                gamesSearchText: '',
                headerLogo: true,
                collapsed: true,
                logo: require('@/../public/images/logos/logo.png'),
                icons: {
                    wheel: require('@/../public/images/icons/menu-icons/wheel.png'),
                    bonus: require('@/../public/images/icons/menu-icons/bonus.png'),
                    deposit: require('@/../public/images/icons/menu-icons/deposit.png'),
                    withdraw: require('@/../public/images/icons/menu-icons/withdraw.png'),
                    terms: require('@/../public/images/icons/menu-icons/terms.png'),
                    home: require('@/../public/images/icons/menu-icons/casino.png'),
                    download: require('@/../public/images/icons/menu-icons/download.png'),
                    level: require('@/../public/images/icons/menu-icons/level.png'),
                    poker: require('@/../public/images/icons/menu-icons/poker.png'),
                },
            }
        },
        computed: {
            userDataFetched() {
                return this.$store.getters.userDataFetched
            },
            userData() {
                return this.$store.getters['userData']
            },
            balance() {
                return this.$store.getters['getBalance']
            },
            largeMenuPage() {
                return this.$route.name === 'TheGame' || this.$route.name === 'Profile'
            },
            siteSettings () {
              return this.$store.getters.getMainSettings
            },
        },
        methods: {
            showLoginModal() {
                this.$root.$emit('bv::show::modal', 'login-modal')
            },
            showRegModal() {
                this.$root.$emit('bv::show::modal', 'reg-modal')
            },
            logout() {
                this.axios.get(
                    this.$_config.baseUrl + '/Api/logout'
                ).then(() => {
                    this.$store.dispatch('removeUserData')
                    this.$store.dispatch('fetchUserData')
                    if (this.$route.name !== 'home') {
                        return this.$router.push({name: 'home'})
                    } else {
                        window.location.reload(true)
                    }
                }).catch(err => {
                    console.log(err)
                })
            },
            gotoGameView() {
                this.$router.push({
                    name: 'TheGame',
                    params: {
                        provider: 'wheeloffortune',
                        gameId: 1
                    }
                })
            },
            isMobile() {
                return this.$_config.environment.mobile
            }
        },
        mounted() {
            this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
                if (isJustShown && collapseId === 'nav-collapse') {
                    this.collapsed = true
                    document.querySelector('.main-container').classList.add('disable-scroll')
                } else {
                    this.collapsed = false
                    document.querySelector('.main-container').classList.remove('disable-scroll')
                }
            })
        },
    }
</script>

<style>
    .navbar-dark {
        background-color: #002260 !important;
    }

    .dropdown-item a {
        color: #0b0b18;
        text-decoration: none;
        font-weight: bold;
    }

    .dropdown-toggle {
        background-color: transparent !important;
        border: none !important;
        padding-top: 0 !important;
        box-shadow: none !important;
    }

    .dropdown-menu {
        outline: none !important;
    }

    .dropdown-item:hover {
        background-color: #001643 !important;
    }

    .dropdown-item:hover a {
        color: white !important;
    }

    .dropdown-toggle::after {
        color: #a4252e;
    }

    .disable-scroll {
        overflow: hidden;
    }

    .navbar-toggler {
        border: none !important;
        outline: none !important;
        right: 0;
        position: absolute;
        top: 15px;
        z-index: 1;
    }

    .active-page span {
        color: #a4252e !important;
        font-weight: bold;
    }

    @media only screen and (max-width: 767px) {
        .navbar-nav .dropdown-menu{
            position: absolute !important;
            bottom: 100%;
            top: unset;
        }
        .navbar-dark {
            padding: 0 !important;
        }

        .main-menu .navbar-collapse {
            position: absolute;
            top: 0;
            width: 100%;
            left: 0;
            background: rgba(1, 15, 42, 0.7) !important;
        }

        .main-menu {
            z-index: 20;
        }

        .menu-list {
            width: 75% !important;
            height: 100%;
            min-height: 100vh;
            float: right;
            background-color: #021945 !important;
        }
    }

    @media only screen and (max-width: 450px) {
        .navbar-nav .dropdown-menu{
            position: absolute !important;
            bottom: unset ;
            top: 100%;
        }
    }
    @media only screen and (max-width: 320px) {
      .menu-list {
        width: 100% !important;
      }
    }
</style>
<style scoped>
    .mobile-auth-btns {
        bottom: 15px;
    }

    .auth-icon {
        top: 20px;
        left: 15px;
        font-size: 12px;
    }

    .login-btn {
        border-bottom: 1px dashed white;
        border-radius: 0;
    }

    .menu-item {
        padding-bottom: 10px;
    }

    .menu-item:hover span {
        color: #a4252e !important;
    }

    .navbar-container {
        background-color: #002261;
    }

    .mobile-menu-logo {
        max-width: 170px;
    }

    .header-logo {
        max-width: 190px;
    }

    @keyframes pulse {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.05);
        }
        100% {
            transform: scale(1);
        }
    }

    .pulse {
        animation: pulse 2s infinite;
        animation-timing-function: linear;
        box-shadow: 0 0 15px 0 #e5001b !important;
    }

    .pulse:hover {
        animation: unset;
    }

    .nav-link {
        text-align: center;
    }

    @media screen and (max-width: 767px) {
        .mobile-auth-btns {
            bottom: 30px;
        }
        .header-logo {
            max-width: 150px;
            position: absolute;
            top: 15px;
            left: 15px;
        }

        .login-btn {
            font-size: 17px !important;
            padding-top: 5px;
        }

        .navbar-container {
            height: 55px !important;
        }
    }

    @media (min-width: 768px) and (max-width: 1199px) {
        .menu-list {
            position: absolute;
            top: 60px;
            text-align: center;
            background-color: #002261;
            left: 0;
            right: 0;
            margin: 0 auto;
            z-index: 2;
            height: 60px;
        }

        .navbar-container {
            height: 0 !important;
        }
    }

    @media (min-width: 1200px) and (max-width: 1500px) {
        .nav-link {
            padding-left: 0 !important;
        }

        .icon {
            width: 18px;
        }

        .header-logo {
            max-width: 160px;
        }

        .pulse {
            padding-left: 20px !important;
            padding-right: 20px !important;
        }
    }
    @media only screen and (max-width: 766px) {
        .register-item{
            width: 50%;
        }
    }
    @media only screen and (max-width: 470px) {
        .mobile-auth-btns {
            bottom: 30px;
        }
        .register-item{
            width: 80%;
        }
    }
</style>
