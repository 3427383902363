<template>
    <div class="messages-container row py-4 w-100 mx-0">
        <b-list-group class="col-12 mx-auto pr-0" v-if="messages.length">
            <b-list-group-item v-for="(message, idx) in messages" :key="idx"
                               class="message-item main-bg py-4 px-4 px-lg-5 mt-4 text-white border-0">
                <b-row>
                    <div class="col-12 px-1 mb-3 mb-lg-2">
                        <span class="fs-14 font-weight-bold text-orange"> {{message.time}}</span>
                    </div>
                    <div class="col-12 col-lg-10 px-1" v-html="message.message"></div>

                    <button v-if="!message.viewed"
                            class="btn markAsReadBtn text-orange position-absolute pr-3"
                            :ref="'markAsRead['+message.id+']'"
                            @click.prevent="()=>{markAsRead(message.id)}"
                            :disabled="markAsReads === message.id">
                        Отметить как прочитанное
                    </button>
                </b-row>
            </b-list-group-item>
        </b-list-group>

        <div class="row w-100 my-3" v-else>
            <span class="text-white mx-auto font-weight-bold">У вас пока нет сообщений</span>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                markAsReads: null
            }
        },
        computed: {
            messages() {
                if (this.$store.getters['getMessages'] != null) {
                    return Object.values(this.$store.getters['getMessages']).reverse()
                }
                return {}
            },
            userData() {
                return this.$store.getters['userData']
            }
        },
        watch: {
            userData(newValue) {
                if (newValue) {
                    this.$router.push({name: 'home'})
                }
            }
        },
        mounted() {
            if (!this.userData) {
                this.$router.push({name: 'home'})
            }
        },
        methods: {
            markAsRead(id) {
                this.markAsReads = id
                this.axios.get(this.$_config.baseUrl + '/Api/MessageViewed/' + id)
                    .catch(err => {
                        console.log(err)
                    })
                this.markAsReads = null
                this.$store.dispatch('fetchMessages')
            }
        }
    }
</script>

<style scoped>
    .messages-container {
        min-height: 200px;
    }

    .message-item {
        border-radius: 7px;
    }

    .message-item:hover {
        box-shadow: 0 0 6px 5px #001844 !important;
    }

    .markAsReadBtn {
        right: 0;
        bottom: 0;
        box-shadow: none !important;
    }

    .markAsReadBtn:hover {
        color: #ffffff !important;
    }
</style>
