<template>
  <b-modal
    id="confirmPassportModal"
    size="lg"
    title="Верификация аккаунта"
    header-class="text-white text-center border-0 pb-0"

    :hide-footer="true"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    v-if="userData"
  >
    <div class="col-12 px-0 mt-3">
      <h5 class="text-center text-success">
        Уважаемый пользователь! Для надежности и сохранности Вашего аккаунта просим Вас пройти верификацию
      </h5>
    </div>
    <div class="col-12 px-0 py-3" >
      <h6 class="text-center text-warning">
        <strong class="text-danger">Важно!</strong>
        Загрузите фотографию главного разворота паспорта, где будут видны все данные, включая серию и номер.
        Документ должен иметь актуальный срок действия. На изображениях должны быть четко видны все данные.
        Минимальный размер изображения 500 кб, максимальный - 5 Мб
      </h6>
      <form
          v-if="userData"
          class="passport-confirmation-form"
          autocomplete="off"
          @submit.prevent="sendImageForVerification">
        <b-form-group class="mb-3 py-2">
          <div class="col-12 px-0">
            <p class="text-center w-100 text-white">1. Загрузите фото Вашего паспорта</p>
            <div class="col-12 px-0 row mx-0 justify-content-between passport-area">
              <div class="col-12 col-md-6 mb-2"
                   v-lazy-container="{ selector: 'img',  loading: require('@/../public/images/icons/game-images-loader.svg') }">
                <img v-if="!passportImage.fileChosen"
                     :data-src="passportImage.example"
                     :src="passportImage.example"
                     alt="Upload verify passport image"
                     class="verification-passport-example">
                <img v-else
                     :src="passportImage.tmp"
                     :data-src="passportImage.tmp"
                     alt="Upload verify passport image"
                     class="verification-passport-example">
              </div>
              <div class="col-12 col-md-6 d-flex">
                <b-form-file
                    v-model="payload.passportFile"
                    @change="onPassportFileChange"
                    required
                    class="main-modal-input upload-passport-file-input"
                    type="file"
                    size="md"
                    :state="passportImage.uploadAllowed"
                    drop-placeholder="Перетащите файл "
                    placeholder="Файл не выбран"
                    :browse-text="passportImage.fileChosen? 'Изменить': 'Выберите файл'"
                >
                </b-form-file>
              </div>
            </div>
          </div>
          <b-row class="w-100 mt-1 mx-auto col-12">
            <alert :message="passportImage.actionMessage"></alert>
          </b-row>
        </b-form-group>
        <b-form-group class="mb-3 py-2">
          <div class="col-12 px-0">
            <p class="text-center w-100 text-white">2. Сделайте селфи-фото с паспортом</p>
            <h6 class="text-center text-warning px-3">
              <strong class="text-danger">Важно!</strong>
              Сделайте селфи с паспортом так, чтобы обязательно были видны серия и данные паспорта!
            </h6>
            <div class="col-12 px-0 row mx-0 justify-content-between passport-area">
              <div class="col-12 col-md-6"
                   v-lazy-container="{ selector: 'img',  loading: require('@/../public/images/icons/game-images-loader.svg') }">
                <img v-if="!selfieImage.fileChosen"
                     :data-src="selfieImage.example"
                     :src="selfieImage.example"
                     alt="Upload verify passport image"
                     class="verification-passport-example">
                <img v-else
                     :src="selfieImage.tmp"
                     :data-src="selfieImage.tmp"
                     alt="Upload verify passport image"
                     class="verification-passport-example">
              </div>
              <div class="col-12 col-md-6 d-flex">
                <b-form-file
                    v-model="payload.selfieFile"
                    @change="onSelfieFileChange"
                    required
                    class="main-modal-input upload-passport-file-input"
                    type="file"
                    size="md"
                    capture
                    :state="selfieImage.uploadAllowed"
                    drop-placeholder="Перетащите файл"
                    placeholder="Файл не выбран"
                    :browse-text="selfieImage.fileChosen? 'Изменить': 'Выберите файл'"
                >
                </b-form-file>
              </div>
            </div>
          </div>
          <b-row class="w-100 mt-1 mx-auto col-12">
            <alert :message="selfieImage.actionMessage"></alert>
          </b-row>
        </b-form-group>
        <b-form-group v-if="passportImage.uploadAllowed && selfieImage.uploadAllowed">
          <div class="col-12 px-0 row mx-0 justify-content-between">
            <div class="col-6 col-sm-4 col-lg-3 px-0">
              <captcha ref="password_confirmation" :captcha_key="'image_upload'" />
            </div>
            <div class="col-6 col-sm-8 px-0">
              <input
                  v-model="payload.captcha"
                  class="form-control main-input"
                  required>
              <small class="d-none d-md-block captcha-hint text-muted">
                Введите символы которые видите на картинке
              </small>
            </div>
          </div>
        </b-form-group>
      </form>

      <b-row class="w-100 mt-3 mx-auto col-12">
        <alert :message="submitResponse"></alert>
      </b-row>

      <div v-if="passportImage.uploadAllowed && selfieImage.uploadAllowed" class="form-group mt-3">
        <button
            name="submit"
            class="btn red-btn d-flex mx-auto px-4"
            :disabled="isSubmitting"
            @click.stop.prevent="sendImageForVerification">
          Отправить
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Captcha from '@/components/Captcha'
import Alert from '@/components/Alert'
import Config from '@/config'

export default {
  name: 'ConfirmPassword',
  data () {
    return {
      allowedTypes:[
        'image/png',
        'image/jpeg',
        'image/jpg',
      ],
      allowedSize: {
        min: 102400,
        max: 5242880
      },
      submitResponse: null,
      isSubmitting: false,
      payload: {
        passportFile: null,
        selfieFile: null,
        captcha: ''
      },
      passportImage: {
        example: Config.baseUrl+'/static/public/images/identify/pass.png',
        uploadAllowed: false,
        tmp: null,
        fileChosen: false,
        actionMessage: null
      },
      selfieImage: {
        example: Config.baseUrl+'/static/public/images/identify/pass-pic-hint.svg',
        uploadAllowed: false,
        tmp: null,
        fileChosen: false,
        actionMessage: null
      }
    }
  },
  computed: {
    userData () {
      return this.$store.getters.userData
    },
    isMobile () {
      return this.$_config.environment.mobile
    }
  },
  components: {
    Alert,
    Captcha
  },
  methods: {
    onPassportFileChange(e) {
      const file = e.target.files[0];
      let size= file.size;
      let type = file.type;
      this.passportImage.actionMessage = {status: 'loading'}
      if(!this.allowedTypes.includes(type)) {
        this.passportImage.fileChosen = false
        this.passportImage.uploadAllowed = false
        this.passportImage.actionMessage = {
          status: 'error', msg: 'Файлы только png,jpg или jpeg'
        }
        return false
      }

      if(size < this.allowedSize.min || size > this.allowedSize.max) {
        this.passportImage.fileChosen = false
        this.passportImage.uploadAllowed = false
        this.passportImage.actionMessage = {
          status: 'error', msg: 'Размер файла от 500кб то 5Мб'
        }
        return false
      }
      this.passportImage.uploadAllowed = true
      this.passportImage.fileChosen = true
      this.passportImage.tmp = URL.createObjectURL(file);
      this.passportImage.actionMessage = null
    },
    onSelfieFileChange(e) {
      const file = e.target.files[0];
      let size= file.size;
      let type = file.type;
      this.selfieImage.actionMessage = {status: 'loading'}
      if (!this.allowedTypes.includes(type)) {
        this.selfieImage.uploadAllowed = false
        this.selfieImage.fileChosen = false
        this.selfieImage.actionMessage = {
          status: 'error', msg: 'Файлы только png,jpg или jpeg'
        }
        return false
      }

      if(size < this.allowedSize.min || size > this.allowedSize.max) {
        this.selfieImage.uploadAllowed = false
        this.selfieImage.fileChosen = false
        this.selfieImage.actionMessage = {
          status: 'error', msg: 'Размер файла от 500кб то 5Мб'
        }

        return false
      }
      this.selfieImage.uploadAllowed = true
      this.selfieImage.fileChosen = true
      this.selfieImage.tmp = URL.createObjectURL(file);
      this.selfieImage.actionMessage = null
    },
    sendImageForVerification () {
      this.submitResponse = { status: 'loading' }
      this.isSubmitting = true
      let formData = new FormData();
      if (!this.payload.passportFile || !this.payload.selfieFile) {
        this.isSubmitting = false
        this.submitResponse = {
          status: 'error', msg: 'Фотографии не выбраны'
        }
        return false
      }

      if (!this.payload.captcha) {
        this.isSubmitting = false
        this.submitResponse = {
          status: 'error', msg: 'Заполните защитный код'
        }
        return
      }

      formData.append('identifyImagePassport', this.payload.passportFile);
      formData.append('identifyImageFacePassport', this.payload.selfieFile);
      formData.append('captcha', this.payload.captcha);

      this.axios.post(
          this.$_config.baseUrl + '/Api/verificationImagesUpload/',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(({ data }) => {
        this.submitResponse = data
        this.payload.captcha = ''
        this.isSubmitting = false
        if (data.status && data.status === 'ok') {
          this.$store.dispatch('fetchUserIdentityData')
          setTimeout(() => {
            this.$root.$emit('bv::hide::modal', 'confirmPassportModal')
            this.$root.$emit('changed')
            window.location.reload()
          }, 3500)
        } else {
          this.$refs.password_confirmation.updateCaptcha()
        }
      }).catch(() => {
        this.isSubmitting = false
        this.submitResponse = {
          status: 'error', msg: 'Ошибка при отправке запроса, повторите позже'
        }
      })
    }
  }
}
</script>
<style>
  .custom-file-label{
    background-color: transparent;
    box-shadow: none !important;
  }
  .custom-file-input ~ .custom-file-label[data-browse]::after{
    color:#ffffff;
    background-color:#418c16 !important;
    outline: none !important;
    border-radius:5px;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    box-shadow: none !important;
  }
</style>
<style scoped>
.verification-passport-example{
  width: 280px;
  margin: auto;
  height: 200px;
  max-width: 100%;
  object-fit: contain;
}
.upload-passport-file-input{
  margin: auto;
  height: 40px;
}
.passport-area{
  height: 200px;
}
@media screen and (max-width: 728px) {
  .verification-passport-example{
    width: 280px;
    margin: auto;
    height: 150px;
    max-width: 100%;
    object-fit: contain;
    display: flex;
  }
}
</style>
