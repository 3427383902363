<template>
    <div class="game-item mb-3 mx-2 cursor-pointer" @click.prevent.stop="gotoGameView">
        <div class="w-100 mw-100 game-img-container d-table"
             v-lazy-container="{ selector: 'img', loading: require('@/../public/images/icons/game-images-loader.svg') }">
            <img :data-src="game.picture" :src="game.picture" class="game-img mw-100 mh-100 w-100 d-table-cell h-100"
                 :alt="game.name">
        </div>
        <div class="games-name-container position-absolute"></div>
        <h6 class="text-white text-center games-name mb-0 position-absolute"> {{game.name}} </h6>
    </div>
</template>

<script>
    export default {
        props: ['game'],
        name: 'GameItemPreview',
        computed: {
            userData() {
                return this.$store.getters['userData']
            }
        },
        methods: {
            gotoGameView() {
                if (!this.userData) {
                    this.$root.$emit('bv::show::modal', 'login-modal')
                    return
                }

                // if (this.game.isLive) {
                //     this.$bvModal.show('liveTablesModal')
                //     const modalAttributes = {
                //         game: this.game,
                //         restoring: false,
                //         isLive: true,
                //         provider: this.game.system
                //     }
                //     this.$store.dispatch('setPopupLiveGame', modalAttributes)
                // } else {
                    this.$router.push({
                        name: 'TheGame',
                        params: {
                            gameName: this.game.name,
                            gameId: this.game.id,
                            game: this.game,
                            provider: this.game.system,
                            isLive: this.game.isLive,
                            restoring: false
                        }
                    })
                // }

            }
        }
    }
</script>

<style scoped>
    .games-name {
        left: 50%;
        top: 85%;
        transform: translate(-50%, -50%);
        font-size: 14px;
    }

    .games-name-container {
        width: 90%;
        border-bottom: 55px solid #002262;
        border-left: 27px solid transparent;
        border-right: 27px solid transparent;
        bottom: 0;
        left: 5%;
    }

    .game-img-container {
        height: 200px;
    }

    .game-img {
        object-fit: contain;
        vertical-align: middle;
    }

    .game-item {
        height: 200px;
        background-color: #00194c;
    }

    .game-item:hover {
        box-shadow: 0 0 12px 8px #0344bd;
    }

    @media only screen and (max-width: 1200px) {
        .games-name-container {
            width: 94%;
            left: 3%;
        }

        .games-name {
            font-size: 12px;
        }
    }
</style>
