<template>
    <div class="row recover-by-email-container mx-0">
        <b-form class="auth-form w-100 my-3 px-0" @submit.prevent="Restore" autocomplete="off" v-if="!show">
            <b-row class="w-100 mx-auto">
                <alert :message="response"></alert>
            </b-row>
            <div class="row mx-0">
                <input class="form-control input-bg input-bg-email" required v-model="restoreEmail.email"
                       aria-label="Email" placeholder="Email" type="email">
            </div>
            <div class="row mx-0 my-4">
                <div class="col-4 col-sm-3 px-0">
                    <captcha ref="pass_change_email_captcha" :captcha_key="'password_restore'" class="w-100"></captcha>
                </div>
                <div class="col-8 col-sm-9 pr-0">
                    <b-form-input v-model="restoreEmail.password_restore_captcha" required
                                  class="input-bg input-bg-dot-auth" aria-label="captcha"></b-form-input>
                </div>
            </div>
            <div class="row mx-0">
                <button class="btn red-btn px-5 mx-auto" type="submit" :disabled="isSubmitting">
                    Отправить
                </button>
            </div>
        </b-form>
        <div class="row w-100 mx-0 my-3" v-else>
            <h2 class="text-white w-100 my-3 text-center">Отлично!</h2>
            <span class="text-white mx-auto">Мы выслали пароль на ваш email</span>
        </div>
    </div>
</template>

<script>
    import Captcha from '@/components/Captcha'
    import Alert from '@/components/Alert'

    export default {
        components: {
            Captcha,
            Alert
        },
        data() {
            return {
                show: false,
                response: null,
                isSubmitting: false,
                restoreEmail: {
                    email: '',
                    password_restore_captcha: ''
                }
            }
        },
        methods: {
            Restore() {
                this.response = {status: 'loading'}
                this.isSubmitting = true
                this.axios.post(
                    this.$_config.baseUrl + '/Api/restorePasswordRequest/', {
                        email: this.restoreEmail.email,
                        password_restore_captcha: this.restoreEmail.password_restore_captcha
                    },
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(({data}) => {
                    this.isSubmitting = false
                    this.response = data
                    if (data.status === 'ok') {
                        this.show = true
                        setTimeout(() => {
                            this.response = null
                            this.restoreEmail.email = ''
                            this.show = false
                        }, 7000)
                    }
                    this.restoreEmail.password_restore_captcha = ''
                    this.$refs.pass_change_email_captcha.updateCaptcha()
                }).catch((err) => {
                    if (err) {
                        this.isSubmitting = false
                        this.response = {status: 'error', msg: err.response.data.errors}
                    }
                })
            }
        }
    }
</script>

