<template>
    <b-modal id="oldVersionPopUp" size="md"
             hide-footer hide-header
             centered no-close-on-esc
             no-close-on-backdrop
             hide-header-close
    >
        <div class="px-0 text-right justify-content-between row mx-0 my-3 my-md-4">
            <div class="col-12 px-0 text-center mb-2">
                <h5 class="text-white">А у нас новый дизайн!</h5>
            </div>
            <div class="col-6 col-sm-6 mb-3 mb-sm-0 pl-0 pl-sm-3">
                <a @click.prevent="goToOldVersion">
                    <img :src="old_logo" alt="SegaSlot old logo" class="mb-4 version-logo cursor-pointer d-flex m-auto">
                    <button class="btn w-100 gray-btn mt-3"> Старый дизайн </button>
                </a>
            </div>
            <div class="col-6 col-sm-6 pr-0 pr-sm-3">
                <a @click.prevent="noNeedOldVersion">
                    <img :src="logo" alt="SegaSlot new logo" class="mb-4 version-logo cursor-pointer d-flex m-auto">
                    <button class="btn red-btn w-100 mt-3"> Новый дизайн </button>
                </a>
            </div>
        </div>
    </b-modal>
</template>

<script>
    export default {
        name: "oldVersionPopUp",
        data() {
            return {
                logo: require('@/../public/images/logos/logo.png'),
                old_logo: require('@/../public/images/logos/old-logo.png')
            }
        },
        methods: {
            noNeedOldVersion() {
                this.$root.$emit('bv::hide::modal', 'oldVersionPopUp')
                return localStorage.setItem('old_v', '1')
            },
            goToOldVersion() {
                return window.location = 'https://old.segaslot.com'
            }
        },
        mounted() {
            this.$root.$emit('bv::show::modal', 'oldVersionPopUp')
        }
    }
</script>

<style>
    #oldVersionPopUp___BV_modal_backdrop_ {
        background-color: #0a0b0c !important;
        opacity: .95 !important;
    }

    #oldVersionPopUp .modal-dialog {
        margin-top: 0 !important;
        top: 0 !important;
    }

    #oldVersionPopUp .modal-content,
    #oldVersionPopUp .modal-header {
        border-radius: 0 !important;
        background-color: #002260 !important;
    }

    .version-logo {
        height: 60px;
        object-fit: contain;
        max-width: 110px;
    }
</style>
