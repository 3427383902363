<template>
    <div class="slider-container position-relative">
        <div class="row mx-0">
            <b-carousel :interval="3000" indicators class="w-100">
                <b-carousel-slide v-for="(slide, index) in slides" :img-src="slide" :key="index"
                                  :img-alt="'SegaSlot'"></b-carousel-slide>
            </b-carousel>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                slides: [
                    require('@/../public/images/slider/slide_1.png'),
                    require('@/../public/images/slider/slide_5.jpg'),
                    require('@/../public/images/slider/slide_3.jpeg'),
                    require('@/../public/images/slider/slide_4.jpeg')
                ]
            }
        }
    }
</script>

<style>
    .slider-container .carousel-indicators {
        margin-bottom: 0;
    }

    .slider-container .carousel-indicators li {
        width: 25% !important;
        height: 1px !important;
        margin: 0 !important;
        border: none !important;
        outline: none !important;
        background-color: #010f20 !important;
    }

    .slider-container .carousel-indicators li.active {
        background-image: linear-gradient(to right, #e5001b, #fb3721);
    }
</style>
