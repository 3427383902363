<template>
    <b-modal id="confirmPhoneModal" size="md"
             :hide-footer="true"
             :no-close-on-esc="true"
             :no-close-on-backdrop="true"
             v-if="userData"
             @close="closeModal"
    >
        <div class="modal-title text-center mb-3">
            <h3 class="text-white">Подтверждение тел. номера</h3>
        </div>
        <form @submit.prevent="confirmPhone" class="mt-4" autocomplete="off" v-if="!successedVerification">
            <Alert :message="callResponse"></Alert>
            <Alert :message="phoneConfirmResponse"></Alert>

            <b-form-group v-if="!confirmCodeSent">
                <div class="row mx-0">
                    <b-form-input v-model="phoneConfirmPayload.phone" required class="input-bg input-bg-phone"
                                  placeholder="Номер телефона" v-mask="['+#(###)-###-##-##', '+#(###)-###-##-###']"></b-form-input>
                </div>
            </b-form-group>

            <b-form-group v-else>
                <div class="row mx-0">
                    <b-form-input v-model="phoneConfirmPayload.call_code"
                                  class="input-bg"
                                  required
                                  v-mask="'####'"
                                  placeholder="Последние 4 цифры номера  с которого поступил звонок"
                                  type="text"></b-form-input>
                </div>
            </b-form-group>
            <b-form-group>
                <div class="row mx-0">
                    <div class="col-4 col-sm-3 px-0">
                        <captcha ref="phone_confirm_captcha" :captcha_key="'phone_confirm_captcha'"></captcha>
                    </div>
                    <div class="col-8 col-sm-9 pr-0">
                        <b-form-input v-model="phoneConfirmPayload.captcha" required
                                      class="input-bg input-bg-dot-auth"></b-form-input>
                    </div>
                </div>
                <div class="row mx-2 mt-4 justify-content-center">
                    <button name="submit" type="submit" class="btn red-btn px-5" v-if="!confirmCodeSent"
                            :disabled="isCalling" @click.prevent="callForPhoneConfirm">
                        Звонить на номер
                    </button>
                    <button name="submit" type="submit" class="btn red-btn px-5" v-else :disabled="isFormSubmitting">
                        Подтвердить
                    </button>
                </div>
            </b-form-group>
        </form>
        <div class="row mx-0 my-5" v-else>
            <span class="mx-auto text-center text-white font-weight-bold">
                Отлично! Ваш номер телефона подтверждён.
            </span>
        </div>
    </b-modal>
</template>

<script>
    import Captcha from '@/components/Captcha'
    import Alert from '@/components/Alert'

    export default {
        props: ['userData'],
        name: 'ConfirmPhone',
        data() {
            return {
                confirmCodeSent: false,
                callResponse: null,
                isCalling: false,
                isFormSubmitting: false,
                phoneConfirmResponse: null,
                phoneConfirmPayload: {
                    phone: '',
                    captcha: '',
                    call_code: ''
                },
                successedVerification: false
            }
        },
        components: {
            Alert,
            Captcha
        },
        methods: {
            closeModal() {
                this.callResponse = null
                this.phoneConfirmResponse = null
            },
            callForPhoneConfirm() {
                this.callResponse = {status: 'loading'}
                this.isCalling = true
                let queryString = `phone=${this.phoneConfirmPayload.phone ? this.phoneConfirmPayload.phone : this.userData.phone}&captcha=${this.phoneConfirmPayload.captcha}`
                this.axios.post(
                    this.$_config.baseUrl + '/Api/callForPhoneConfirmation', queryString, {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(({data}) => {
                    this.callResponse = data
                    if (data.status === 'ok') {
                        this.confirmCodeSent = true
                    }
                    this.phoneConfirmPayload.captcha = ''
                    this.isCalling = false
                }).catch(() => {
                    this.callResponse = {
                        status: 'error', msg: 'Ошибка при отправке запроса, повторите позже'
                    }
                    this.phoneConfirmPayload.captcha = ''
                    this.isCalling = false
                })
                this.$refs.phone_confirm_captcha.updateCaptcha()
            },
            confirmPhone() {
                this.phoneConfirmResponse = {status: 'loading'}
                this.isFormSubmitting = true
                this.callResponse = null
                let queryString = `phone_confirmation_captcha=${this.phoneConfirmPayload.captcha}&call_code=${this.phoneConfirmPayload.call_code}`

                this.axios.post(
                    this.$_config.baseUrl + '/Api/confirmPhoneAfterCall', queryString, {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(({data}) => {
                    this.phoneConfirmResponse = data
                    if (data.status === 'ok') {
                        this.$store.dispatch('fetchUserData', true)
                        this.successedVerification = true
                        setTimeout(() => {
                            this.phoneConfirmResponse = null
                            this.$root.$emit('bv::hide::modal', 'confirmPhoneModal')
                        }, 5000)
                    }
                    this.phoneConfirmPayload.captcha = ''
                }).catch(() => {
                    this.phoneConfirmResponse = {status: 'error', msg: 'Ошибка при отправке запроса, повторите позже'}
                })
                this.$refs.phone_confirm_captcha.updateCaptcha()
                this.isFormSubmitting = false
            }
        },
        mounted() {
            if (this.userData) {
                this.phoneConfirmPayload.phone = this.userData.phone
            }
        }
    }
</script>

<style scoped>
    @media only screen and (max-width: 767px) {
        h3 {
            font-size: 1.2rem;
        }
    }
</style>
