<template>
    <div v-if="userData" class="mt-5 color-white">
        <div class="row px-0" v-if="histories && histories.length">
            <div v-for="(history, index) in histories" :key="index" class="col-12 history pt-2 pb-2 cursor-pointer">
                <div class="winner row d-flex justify-content-between align-items-center" @click="showDateHistory(index)">
                    <div class="pay col-3 col-md-3 text-center h-100">
                        <span v-if="history.method_type == 'card'">Карта</span>
                        <span v-else-if="history.method_type == 'sbp'">СБП</span>
                        <span v-else>Кошелек</span>
                    </div>
                    <div class="pay col-4 col-md-3 text-center h-100">
                        {{ history.amount }}
                    </div>
                    <div class="pay text-center col-md-3 d-none d-md-block output"  v-if="history.comment">
                        {{ outputTo(history.comment) }}
                    </div>
                    <div class="pay text-center col-5 col-md-3 pay-status" v-if="history.status">
                        <button type="button" class="text-center mx-auto"
                        v-for="(status, index) in statuses" 
                        :key="index"
                        v-if="history.status == index"
                        :class="status.type"
                         >
                         {{ status.name }}
                        </button>
                    </div>
                    <div class="pay text-center col-5 col-md-3 pay-status" v-else>
                        <button type="button" class="confirmed text-center mx-auto">Успешно</button>
                    </div>
                </div>
                <div class="history-desc" :class="{ active: index === activeHistory }">
                    <span class="d-block">Сумма транзакции: {{ history.amount }}</span>
                    <span class="d-block">Дата операции: {{ history.date }}</span>
                    <span class="d-block d-md-none" v-if="history.comment">Вывод на {{ outputTo(history.comment) }}</span>
                </div>
            </div>
        </div>
        <div class="py-lg-4 mx-0 col-12 col-lg-10 col-xl-8 mx-auto px-0" v-else>
            <div class="text-center">
                <h5>История отсутствует </h5>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    name: "History",
    props: {
        histories: Array || null
    },
    data(){
        return{
         activeHistory: null,
         statuses:{
            1:{
                type:'panding',
                name:'В ожидании',
            },
            2:{
                type:'support_confirmed',
                name:'Суппорт подтвердил',
            },
            3:{
                type:'confirmed',
                name:'Подтверждено',
            },
            4:{
                type:'canceled',
                name:'Отменено',
            },
            5:{
                type:'canceled_returned',
                name:'Возвращено',
            },
         }
        }
    },
    computed: {
        userData() {
            return this.$store.getters.userData
        }
    },
    methods:{
        showDateHistory(value){
            if(this.activeHistory != value){
                this.activeHistory = value;
            } else {
                this.activeHistory = null;
            } 
        },
        outputTo(val){
            const name = val.split(' ');
            return name[3];
        }
    },
    mounted() {
        if (!this.userData) {
            return this.$router.push({ name: 'login' })
        } 
    }
}

</script>

<style scoped>
  .history{
    min-height: 40px;
    border-bottom: 1px solid #585858;;
    font-size: 12px;
  }
  .pay button{
      border-radius: 34px;
      min-width: 100px;
      height: 35px;
      border: unset!important;
      color: #ffff;
      font-size: 12px;
  }
  .pay .confirmed{
      background-color: #3d9b2a!important;
  }
  .pay  .panding{
      background-color: #2d61f0!important;
  }
  .pay  .support_confirmed{ 
    background-color: #307c0d!important;
  }
  .pay  .canceled_returned{
      background-color: #daa208!important;
  }
  .pay  .canceled{
      background-color: #f02d2d!important;
  }
  .history-desc {
      display: none;
      text-align: start;
  }
  .active {
      display: block;
      animation: fadeIn 1s ease forwards;
  }

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width:992px) {
  .history,
  .pay{
      font-size: 10px;
  }
}

</style>