<template>
    <b-modal v-show="showPopup"
             :hide-footer="true"
             :no-close-on-backdrop="true"
             :no-close-on-esc="true"
             id="login-modal"
             @close="resetData"
    >
        <div class="row mx-0 justify-content-center">
            <h3 class="text-white">Войти</h3>
        </div>
        <div class="row mx-0 px-0 px-lg-4">
            <alert :message="response"></alert>
            <b-form class="auth-form w-100 " @submit.prevent="Login" autocomplete="off">
                <div class="row w-100 mx-0">
                    <div class="col-12 px-0">
                        <input class="form-control input-bg input-bg-login" required type="text" v-model="payload.login"
                               aria-label="user-login">
                        <div class="placeholder">Логин <span class="text-orange">*</span></div>
                    </div>
                    <div class="col-12 px-0 mt-3">
                        <input class="form-control input-bg input-bg-pass" required type="password"
                               v-model="payload.password"
                               aria-label="user-password">
                        <div class="placeholder">Пароль <span class="text-orange">*</span></div>
                    </div>
                </div>
                <div class="row mx-0 my-4">
                    <div class="col-6 px-1 px-lg-3">
                        <button class="btn red-btn px-5 w-100" @click.prevent="Login" :disabled="isSubmitting">
                            Войти
                        </button>
                    </div>
                    <div class="col-6 px-1 px-lg-3 pt-1 text-center">
                        <a class="text-white fs-14 cursor-pointer recover-link pb-2" @click.prevent="Recover">
                            Восстановить пароль
                        </a>
                    </div>
                </div>
            </b-form>
        </div>
    </b-modal>
</template>

<script>
    import Alert from '@/components/Alert'

    export default {
        name: 'loginPopup',
        data() {
            return {
                isSubmitting: false,
                response: null,
                payload: {
                    login: '',
                    password: ''
                }
            }
        },
        components: {
            Alert
        },
        computed: {
            userData() {
                return this.$store.getters['userData']
            },
            showPopup() {
                return this.$store.getters['userData'] ? true : false
            }
        },
        methods: {
            resetData() {
                this.response = null
                this.payload.login = ''
                this.payload.password = ''
            },
            Login() {
                this.response = {status: 'loading'}
                this.isSubmitting = true
                this.axios.post(
                    this.$_config.baseUrl + '/Api/Login', {
                        authorize: this.payload
                    }
                ).then((userData) => {
                    let data = userData.data
                    this.isSubmitting = false
                    this.response = data
                    if (data.status === 'ok') {
                        this.$store.dispatch('fetchApiData')
                        this.$store.dispatch('fetchUserData')
                        this.response = {status: 'ok', msg: 'Добро пожаловать'}
                        this.$store.dispatch('fetchMessages')
                        this.$store.dispatch('getWheelStats')
                        this.$store.dispatch('getMainSettings')
                        setTimeout(() => {
                            this.$root.$emit('bv::hide::modal', 'login-modal')
                            this.resetData()
                        }, 1500)
                    } else {
                        this.payload.password = ''
                    }
                }).catch((err) => {
                    if (err) {
                        this.isSubmitting = false
                        this.response = {status: 'error', msg: err.response.data.errors}
                    }
                })
            },
            Recover() {
                this.$root.$emit('bv::hide::modal', 'login-modal')
                this.resetData()
                this.$root.$emit('bv::show::modal', 'recover-modal')
            }
        }
    }
</script>

<style scoped>
    .recover-link:hover {
        border-bottom: 2px dotted white;
    }
</style>
