<template>
  <div class="bonus-container main-bg-light row w-100 mx-0 py-5">
    <div class="col-11 px-0 mx-auto pt-1 pt-md-5 pt-lg-3 pt-xl-0">
        <h2 class="text-white mb-5 text-center">Уровни игроков</h2>
        <div class="col-12 px-0" v-if="levels">
            <table class="table table-stripped text-white text-center levels-table">
                <thead>
                    <tr>
                        <th>Уровень</th>
                        <!-- <th class="">Кэшбэк</th> -->
                        <th class="d-none d-lg-table-cell">Доступные бонусы</th>
                        <th class="d-none d-lg-table-cell">Акции</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(level, idx) in levels" :key="idx" :class="{'active':userLevel === level.level}">
                        <td>
                            <img :src="imgUrl(level.icon)" alt="" class="level-img">
                            <span class="d-block w-100">{{level.title}}</span>
                            <div class="level-progressbar" v-if="uDepSum && userLevel === level.level">
                                <b-progress :value="uDepSum" :max="level.max_border" animated></b-progress>
                                <span class="hint-text">{{reachProgress(level.max_border).levelPartReachedUnRounded}} Очков из 100</span>
                            </div>
                        </td>
                      <!-- <td class="">{{level.cashback}} %
                        <div class="d-block d-lg-none">
                          <button class="btn red-btn" type="button"
                          @click.prevent="showLevelInfo(level)">Подробнее</button>
                        </div>
                      </td> -->
                      <td class="d-none d-lg-table-cell" v-html="level.bonuses"></td>
                      <td class="d-none d-lg-table-cell" v-html="level.promotions"></td>
                    </tr>
                </tbody>
            </table>
        </div>
      <UserLevelInfoModal></UserLevelInfoModal>
    </div>
  </div>
</template>

<script>
import UserLevelInfoModal from '@/components/UserLevelInfoModal'

export default {
    name: "UserLevels",
    components: {
      UserLevelInfoModal
    },
    computed: {
      levels () {
        return this.$store.getters.clientsLevel
      },
      uDepSum () {
        return this.$store.getters.uDepSum
      },
      userLevel () {
        const userData = this.$store.getters.userData
        if(userData) {
          return userData.level
        }
        return false
      },

    },
    methods: {
      showLevelInfo(level) {
        this.$root.$emit('bv::show::modal', 'levelInfoModal')
        this.$root.$emit('levelBonus', level.bonuses)
        return this.$root.$emit('levelPromotion', level.promotions)
      },
      reachProgress ($maxBorder) {
        let levelPartReached = (this.uDepSum/$maxBorder)*100;
        const levelPartReachedUnRounded = (levelPartReached).toFixed(4);
        levelPartReached = Math.round(levelPartReached);
        return {
          levelPartReached, levelPartReachedUnRounded: parseFloat(levelPartReachedUnRounded)
        }
      },
      imgUrl (path) {
        if(window.origin.indexOf('sega') === -1) {
          return 'https://segaslot.com' + path
        }
        return path
      }
    }

}
</script>

<style scoped>
    td{
        vertical-align:middle !important;
        position: relative;
    }
    .level-img{
        width: 120px;
    }
    tr.active{
        box-shadow: 0px 0px 15px 5px #ffffff7d;
    }
    tr.active td:nth-child(2):before{
        display: block;
        content: 'Ваш Уровень';
        font-size: 16px;
        font-weight: 800;
        width: 120px;
        text-decoration: underline;
        color: #e31919;
        margin: auto;
    }
</style>
