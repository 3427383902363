<template>
  <div class="col-12 px-0 ">
    <b-modal id="levelInfoModal"
             ref="levelInfoModal"
             size="lg"
             :hide-header="true"
             :hide-footer="true">
      <h3 class="text-white text-center my-4">Информация об уровне</h3>
      <div class="row mx-0 message-text-container text-white px-0 px-lg-3 py-3 mb-1">
        <div class="col-12 mb-4 px-0" v-if="bonus">
          <h5 class="text-center">Бонусы</h5>
          <p class="text-white" v-html="bonus">  </p>
        </div>
        <div class="col-12 px-0" v-if="promotion">
          <h5 class="text-center" v-if="promotion && promotion.length">Акции</h5>
          <p class="text-white" v-html="promotion">  </p>
        </div>
      </div>

      <div class="col-12 px-0 text-right pb-5 pb-lg-0">
        <div class="d-inline-block">
          <button class="btn btn-danger  font-weight-bold" @click.prevent="hideLevelModal">
            Закрыть
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'LevelInfo',
  data() {
    return {
      bonus: null,
      promotion: null
    }
  },
  computed: {

  },
  methods: {
    hideLevelModal() {
      return this.$root.$emit('bv::hide::modal', 'levelInfoModal')
    }
  },
  mounted() {
    const _this = this
    this.$root.$on('levelBonus', function (value) {
      _this.bonus = value
    })
    this.$root.$on('levelPromotion', function (value) {
      _this.promotion = value
    })
  },
}
</script>

<style scoped>
.markAsReadBtn {
  box-shadow: none !important;
}

.markAsReadBtn:hover {
  color: #ffffff !important;
}
</style>
