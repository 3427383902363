import browser from 'browser-detect'

let baseUrl = (
  window.location.href.indexOf('192.168') !== -1 ||
  window.location.href.indexOf('127.0.0.1') !== -1 ||
  // window.location.href.indexOf('dev.segaslot.com') !== -1 ||
  window.location.href.indexOf('localhost') !== -1 ||
  window.location.href.indexOf('dev.') !== -1
) ? 'https://segaslot.com' : window.location.origin

const Config = {
    baseUrl: baseUrl,
    captchaUrl: baseUrl + '/Api/captcha',
    payInUrl: baseUrl + '/Api/payInConfirm/',
    passwordChangeEmailPath: `${baseUrl}/Api/sendPasswordChangeEmail/`,
    passwordChangeSmsPath: `${baseUrl}/Api/sendPasswordChangeSms/`,
    passwordChangeCallPath: `${baseUrl}/Api/passwordChangeCall/`,
    passwordChangeRequests: {
        mail: `${baseUrl}/Api/PasswordChangeRequest/mail/`,
        sms: `${baseUrl}/Api/PasswordChangeRequest/sms/`,
        call: `${baseUrl}/Api/PasswordChangeRequest/call/`,
    },
    closeGameUrl: baseUrl + '/Api/closeGame',
    environment: browser(),
    storageUrl: "https://gameimages.hfikq.upcloudobjects.com",
    isIos () {
        return this.environment.os.toLowerCase().indexOf('ios') !== -1 ||
            this.environment.os.toLowerCase().indexOf('os x') !== -1
    },
    isAndroid () {
        return this.environment.os.toLowerCase().indexOf('android') !== -1
    },
    isMobile () {
        return this.environment.mobile
    },
    makeQueryStringFromObject (obj) {
        let str = []
        for (let p in obj) {
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
            }
        }
        return str.join('&')
    },
    install (Vue) {
        Vue.prototype.$config = this
        Vue.prototype.$_config = this
    }
}

export default Config
