<template>
    <header-menu></header-menu>
</template>

<script>
    import HeaderMenu from '@/components/Menu'

    export default {
        name: 'the-header',
        components: {
            HeaderMenu
        }
    }
</script>
