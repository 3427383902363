<template>
    <b-modal v-model="showPopup"
             :hide-footer="true"
             :no-close-on-backdrop="true"
             :no-close-on-esc="true"
             id="reg-modal"
    >
        <div class="row w-100 mx-0 justify-content-center">
            <h3 class="text-white">Регистрация</h3>
        </div>
        <div class="row mx-0 px-0 px-lg-4">
            <alert :message="response"></alert>
            <b-form class="auth-form mt-3 w-100" @submit.prevent="Login" autocomplete="off">
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <input class="form-control input-bg input-bg-login" required v-model="payload.user_login"
                               aria-label="user-login">
                        <div class="placeholder">Логин <span class="text-orange">*</span></div>
                    </div>
                </div>
                <div class="row mx-0 my-4">
                    <div class="col-12 px-0">
                        <input class="form-control input-bg input-bg-pass" required type="password"
                               v-model="payload.user_password"
                               aria-label="user-password">
                        <div class="placeholder">Пароль <span class="text-orange">*</span></div>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <input class="form-control input-bg input-bg-email" required v-model="payload.user_email"
                               aria-label="user-email">
                        <div class="placeholder">E-mail адрес <span class="text-orange">*</span></div>
                    </div>
                </div>
                <div class="row mx-0 my-4">
                    <div class="col-12 px-0">
                        <the-mask :mask="['+#(###)-###-##-##', '+#(###)-###-##-###']" class="form-control input-bg input-bg-phone"
                                  v-model="payload.user_phone"
                                  required type="text" aria-label="user-phone"></the-mask>
                        <div class="placeholder">Номер телефона <span class="text-orange">*</span></div>
                    </div>
                </div>
                <div class="row mx-0 my-4">
                    <div class="col-12 px-0">
                        <input class="form-control input-bg input-bg-name" required v-model="payload.user_name"
                               aria-label="user-name">
                        <div class="placeholder">Ваше имя <span class="text-orange">*</span></div>
                    </div>
                </div>
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <input class="form-control input-bg input-bg-dot-auth" required v-model="payload.promo_code"
                               aria-label="promo-code">
                        <div class="placeholder">Промо-код</div>
                    </div>
                </div>
                <div class="row mx-0 my-4 justify-content-center">
                    <button class="btn red-btn px-5 my-3" @click.prevent="Registration" :disabled="isSubmitting">
                        Регистрация
                    </button>
                </div>
            </b-form>
        </div>
    </b-modal>
</template>

<script>
    import Alert from '@/components/Alert'

    export default {
        components: {
            Alert
        },
        data() {
            return {
                response: null,
                showPopup: false,
                isSubmitting: false,
                payload: {
                    user_login: '',
                    user_email: '',
                    user_password: '',
                    user_phone: '',
                    promo_code: '',
                    user_currency: 1,
                    user_name: ''
                }
            }
        },
        methods: {
            Registration() {
                this.response = {status: 'loading'}
                this.isSubmitting = true
                this.axios.post(
                    this.$_config.baseUrl + '/Api/registration',
                    this.payload, {
                        withCredentials: true
                    }
                ).then(({data}) => {
                    this.isSubmitting = false
                    this.response = data
                    if (data.status === 'ok') {
                        this.$store.dispatch('fetchUserData')
                        this.$store.dispatch('getMainSettings')
                    }
                }).catch((err) => {
                    if (err) {
                        this.isSubmitting = false
                        this.response = {status: 'error', msg: err.response.data.errors}
                    }
                })
            }
        },
        computed: {
            userData() {
                return this.$store.getters['userData']
            }
        },
        watch: {
            userData(newValue) {
                if (newValue) {
                    this.$root.$emit('bv::hide::modal', 'reg-modal')
                }
            }
        },
        mounted() {
            if (this.userData) {
                this.$root.$emit('bv::hide::modal', 'reg-modal')
            }
        }
    }
</script>

<style scoped>
    .placeholder {
        top: 10px;
        left: 18px;
        font-size: 15px;
    }
</style>
