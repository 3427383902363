<template>
    <div class="account-container row py-4 w-100 mx-0" v-if="userData">
        <b-list-group class="account-list col-12 px-0 col-lg-11 col-xl-10 mx-auto">
            <b-list-group-item class="px-1 px-md-3">
                <h6 class="text-white"></h6>
                <div class="row mx-0" v-if="wheelStats">
                    <div class="progress mt-1 mx-auto position-relative">
                        <div class="progress-bar" v-bind:style="{ width: wheelStats.progressPercent + '%' }"></div>
                        <div class="progress-text font-weight-bold position-absolute">
                            <span class="text-white">{{wheelStats ? wheelStats.progressPercent + '%' : 0 + '%'}}</span>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 my-4">
                    <h5 class="text-white w-100 mb-3 text-center">
                        У Вас - {{wheelStats ? wheelStats.lastMonthDeposit : 0}} / {{wheelStats ?
                        wheelStats.profitBorder : 0}}
                    </h5>
                    <span class="text-white font-weight-bold fs-14">
                        Колесо Фортуны активируется при пополнении от 5000 руб. в течении месяца.(месяц считается до
                        текущего дня)
                    </span>
                    <span class="text-white fs-14 mt-2">
                        К примеру: для доступа к Колесу Фортуны сегодня, сумма Ваших пополнений за {{setDates.monthAgo}}
                        до {{setDates.now}} должна быть больше 5000 руб.
                    </span>
                </div>
            </b-list-group-item>
            <b-list-group-item class="px-1 px-md-3">
                <div class="row mx-0">
                    <div class="col-6 col-md-3 col-lg-2 px-2"><span class="text-white">Логин</span></div>
                    <div class="col-6 col-md-3 col-lg-2"><span class="text-white">{{userData.login}}</span></div>
                </div>
            </b-list-group-item>
            <b-list-group-item class="px-1 px-md-3">
                <div class="row mx-0">
                    <div class="col-6 col-md-3 col-lg-2 px-2" :class="[!userData.name ? 'd-none d-md-block' : 'col-6']">
                        <span class="text-white">Ваше имя</span>
                    </div>
                    <div class="col-6 col-md-3 col-lg-7" v-if="userData.name"><span
                            class="text-white">{{userData.name}}</span></div>
                    <div class="col px-0" v-else>
                        <form class="w-100">
                            <Alert :message="updateNameResponse"></Alert>
                            <fieldset class="form-group row m-0">
                                <div class="col-6 col-md-5 col-lg-8 px-0">
                                    <input type="text" placeholder="Ваше имя *" required aria-required="true"
                                           :class="[isMobile ? 'input-bg form-control' : 'input-bg input-bg-dot-auth form-control']" v-model="usersName">
                                </div>
                                <div class="col-6 col-lg-4 px-0 text-lg-center">
                                    <b-button class="btn red-btn px-1 px-sm-5" @click.stop.prevent="updateUsersName">
                                        Подтвердить
                                    </b-button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </b-list-group-item>
            <b-list-group-item class="px-1 px-md-3">
                <div class="row mx-0">
                    <div class="col-6 col-md-3 col-lg-2 px-2"
                         :class="[!userData.phone_confirmed ? 'd-none d-md-block' : 'col-6']">
                        <span class="text-white">Тел. номер </span>
                    </div>
                    <div class="col-6 col-md-3 col-lg-7">
                        <span class="text-white"> {{userData.phone}} </span>
                    </div>
                    <div :class="[!userData.phone_confirmed ? 'col-6 col-lg-3 px-0 text-lg-center' : 'd-none']">
                        <b-button @click.prevent="confirmPhone" class="btn red-btn px-1 px-sm-5">
                            Подтвердить
                        </b-button>
                    </div>
                </div>
            </b-list-group-item>
            <b-list-group-item class="px-1 px-md-3">
                <div class="row mx-0">
                    <div class="col-6 col-md-3 col-lg-2 px-2"><span class="text-white">Баланс</span></div>
                    <div class="col-6 col-md-3 col-lg-7">
                        <span class="text-white">{{userData.balance}} {{userData.currency_name}}</span>
                    </div>
                    <div class="col-md-6 col-lg-3 d-none d-md-block text-lg-center">
                        <router-link :to="{ name: 'deposit' }" class="btn btn red-btn px-1 px-sm-5 btn-secondary">
                            Пополнить
                        </router-link>
                    </div>
                </div>
            </b-list-group-item>
            <b-list-group-item class="px-1 px-md-3">
                <div class="row mx-0">
                    <div class="col-6 col-md-3 col-lg-2 px-2">
                        <span class="text-white">Пароль</span>
                    </div>
                    <div class="col-md-3 col-lg-7 d-none d-md-block">
                        <span class="text-white"> ******** </span>
                    </div>
                    <div class="col-6 col-md-6 col-lg-3 px-2 text-lg-center ">
                        <b-button @click.prevent="changePassword" class="btn red-btn px-2 px-sm-5">
                            Изменить пароль
                        </b-button>
                    </div>
                </div>
            </b-list-group-item>
            <b-list-group-item class="px-1 px-md-3">
                <div class="row mx-0">
                    <div class="col-6 col-md-3 col-lg-2 px-2"><span class="text-white">Статус</span></div>
                    <div class="col-6 col-md-3 col-lg-2"><span class="text-white">Активный</span></div>
                </div>
            </b-list-group-item>
            <b-list-group-item class="px-1 px-md-3">
                <div class="row mx-0">
                    <div class="col-6 col-md-3 col-lg-2 px-2"
                         :class="[!userData.email_confirmed ? 'd-none d-md-block' : 'col-6']">
                        <span class="text-white">Email</span>
                    </div>
                    <div class="col-6 col-md-3 col-lg-7">
                        <span class="text-white">{{userData.email}}</span>
                    </div>
                    <div :class="[!userData.email_confirmed ? 'col-6 col-lg-3 px-2 text-lg-center' : 'd-none']">
                        <b-button @click.prevent="confirmEmail" class="btn red-btn px-3 px-sm-5 mx-auto">
                            Подтвердить
                        </b-button>
                    </div>
                </div>
            </b-list-group-item>
            <b-list-group-item class="px-1 px-md-3">
                <div class="row mx-0">
                    <div class="col-6 col-md-3 col-lg-2 px-2">
                        <span class="text-white">Верификация</span>
                    </div>
                    <div class="col-6 col-md-3 col-lg-7">
                        <p class="d-inline-block user-verified-text text-white" v-if="accountIdentifyStatus">
                            <span v-html="accountIdentifyStatus"></span>
                            <br>
                            <small v-if="accountIdentifyStatusDesc" v-html="accountIdentifyStatusDesc"></small>
                        </p>
                    </div>
                    <div class="col-12 col-lg-3  px-0"
                         :class="userData.verified === 0 ? 'text-lg-center' : 'text-center'">
                        <b-button name="identifyPerson"
                                  v-if="showIdentificationButton"
                                  class="btn red-btn px-2 px-sm-5"
                                  @click.prevent="confirmPassport">
                            Пройти верификацию
                        </b-button>
                        <small v-else-if="!showIdentificationButton && userData.verified === 1"
                               class="text-success user-detail-state">
                            подтверждено
                        </small>
                        <small v-else class="text-warning user-detail-state">
                            в процессе
                        </small>
                    </div>
                </div>
            </b-list-group-item>

        </b-list-group>
        <ConfirmPhoneViaCall :userData="userData"
                             v-if="siteSettings && siteSettings.phoneActionsMethod==='call'"></ConfirmPhoneViaCall>
        <ConfirmPhone :userData="userData" v-else></ConfirmPhone>
        <ConfirmEmail :userData="userData"></ConfirmEmail>
        <ChangePassword :userData="userData"></ChangePassword>
        <ConfirmPassport :user-data="userData" v-if="userData.verified !== 1"
                         @changed="updateState()"></ConfirmPassport>
    </div>
</template>

<script>
  import ConfirmPhone from '@/components/user/profile/ConfirmPhone'
  import ConfirmPhoneViaCall from '@/components/user/profile/ConfirmPhoneViaCall'
  import ConfirmEmail from '@/components/user/profile/ConfirmEmail'
  import ChangePassword from '@/components/user/profile/ChangePassword'
  import ConfirmPassport from "@/components/user/profile/ConfirmPassport";
  import Alert from '@/components/Alert'

  export default {
    data() {
      return {
        identityData: null,
        accountIdentifyStatus: null,
        accountIdentifyStatusDesc: null,
        showIdentificationButton: false,
        updateNameResponse: null,
        usersName: ''
      }
    },
    components: {
      ConfirmPhone,
      ConfirmEmail,
      ChangePassword,
      ConfirmPassport,
      ConfirmPhoneViaCall,
      Alert
    },
    async created() {
      await this.$store.dispatch('fetchUserIdentityData')
    },
    computed: {
      isMobile() {
        return this.$_config.environment.mobile
      },
      siteSettings() {
        return this.$store.getters.getMainSettings
      },
      userData() {
        return this.$store.getters.userData
      },
      wheelStats() {
        return this.$store.getters.getWheelStats
      },
      userIdentityData() {
        return this.$store.getters.identityData
      },
      setDates() {
        let date = new Date()
        let monthAgo = new Date(date)
        monthAgo.setDate(monthAgo.getDate() - 30)
        date = new Date(date).toLocaleString('ru-RU', {hour12: false})
        monthAgo = new Date(monthAgo).toLocaleString('ru-RU', {hour12: false})

        return {now: date, monthAgo: monthAgo}
      }
    },
    methods: {
      changePassword() {
        this.$bvModal.show('changePasswordModal')
      },
      confirmPhone() {
        this.$bvModal.show('confirmPhoneModal')
      },
      confirmEmail() {
        this.$bvModal.show('confirmEmailModal')
      },
      confirmPassport() {
        this.$root.$emit('bv::show::modal', 'confirmPassportModal')
      },
      setAccountIdentifyStatus() {
        if (!this.userData) {
          return
        }
        let accountVerified = this.userData.verified
        let identifyConfirmedStatus = false

        if (this.identityData) {
          identifyConfirmedStatus = this.identityData.approved
        }
        this.showIdentificationButton = false
        this.accountIdentifyStatus = null
        this.accountIdentifyStatusDesc = null

        switch (accountVerified) {
          case 0:
            this.showIdentificationButton = true
            this.accountIdentifyStatus = '<span class="text-danger">Аккаунт не верифицирован</span>'
            if (identifyConfirmedStatus === 2) {
              const reason = this.identityData.reject_reason
              this.accountIdentifyStatusDesc = `<span class="text-danger">Верификация отказано - ${reason}</span>`
            }
            if (identifyConfirmedStatus === 0) {
              this.showIdentificationButton = false
              this.accountIdentifyStatus = '<span class="text-warning">Данные в обрaботке</span>'
            }
            break;
          case -1:
            this.showIdentificationButton = true
            this.accountIdentifyStatus = '<strong class="text-danger">Необхадимо пройти верификацию !</strong>'

            if (identifyConfirmedStatus === 0) {
              this.showIdentificationButton = false
              this.accountIdentifyStatus = '<span class="text-warning">Данные в обрaботке</span>'
            }
            if (identifyConfirmedStatus === 2) {
              const reason = this.identityData.reject_reason
              this.showIdentificationButton = true
              this.accountIdentifyStatus = '<span class="text-warning">Получено запрос на верификацию</span>'
              this.accountIdentifyStatusDesc = `<span class="text-danger">Верификация отказано - ${reason}</span>`
            }
            break
          case 1:
            this.accountIdentifyStatus = '<span class="text-success">Аккаунт верифицирован</span>'
            break
        }
      },
      updateState() {
        this.identityData = this.$store.getters.identityData
        this.setAccountIdentifyStatus()
      },
      updateUsersName() {
        this.updateNameResponse = {status: 'loading'}
        let queryString = `name=${this.usersName}`
        this.axios.post(
          this.$_config.baseUrl + '/Api/updateUserName/', queryString,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        ).then(({data}) => {
          this.updateNameResponse = data

          if (data.status !== "error") {
            this.$store.dispatch('fetchUserData')
            setTimeout(() => {
              this.updateNameResponse = null
            }, 5000)
          }
        }).catch(() => {
          this.updateNameResponse = {
            status: 'error', msg: 'Ошибка при отправке запроса, повторите позже'
          }
        })
      }
    },
    mounted() {
      if (!this.userData) {
        return this.$router.push({name: 'home'})
      }
      if (this.userIdentityData) {
        this.identityData = this.userIdentityData
      }

      setTimeout(() => {
        this.updateState()
      }, 1000)
    },
    updated() {
      this.identityData = this.$store.getters.identityData
    },
    watch: {
      identityData: {
        handler: function () {
          this.updateState()
        },
        deep: true

      },
      userData: {
        handler: function (data) {
          if (data) {
            this.setAccountIdentifyStatus()
          }
        },
        deep: true
      }
    }
  }
</script>

<style scoped>
    span {
        word-break: break-all;
    }

    .text-link {
        color: #2174f6 !important;
    }

    .account-list .list-group-item {
        border-radius: unset;
        background-color: #00153b;
        border-bottom: 1px solid #001844;
    }

    .account-list .list-group-item:first-child {
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        background-color: #001844;
    }

    .account-list .list-group-item:last-child {
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        border-bottom: none;
    }

    .progress-text {
        left: 50%;
        top: 7px;
        font-size: 18px;
    }

    .progress {
        background-color: #07162e;
        height: 40px;
        width: 50%;
    }

    .progress-bar {
        background-image: linear-gradient(to right, #e5001b, #fb3721);
        border-radius: 7px;
    }

    @media only screen and (max-width: 767px) {
        .progress {
            width: 100%;
        }
    }

    @media (min-width: 768px) and (max-width: 1199px) {
        .progress {
            width: 80%;
        }
    }
</style>
