<template>
    <div class="w-100 px-2 px-xl-3" v-if="tab.games">
        <div v-if="tab.games.length">
            <div class="row mx-0 no-gutters">
                <div class="col-6 col-sm-4 col-md-3 col-lg-2" v-for="(game, i) in tab.games" :key="'game_'+i">
                    <GameItemPreview :game="game"></GameItemPreview>
                </div>
            </div>
        </div>
        <div class="w-100 text-center" v-else>
            <h3 v-if="tab.searchText" class="text-white mt-4">
                По запросу «{{ tab.searchText }}» ничего не найдено (в этих категориях)
            </h3>
            <h3 v-else class="text-white mt-4">
                <i class="fa fa-spinner fa-spin fa-fw my-5 text-white"></i>
            </h3>
        </div>
    </div>
</template>

<script>
    import GameItemPreview from "./GameItemPreview";

    export default {
        props: ['tab'],
        name: "GameTabTemplate",
        components: {
            GameItemPreview,
        }
    }
</script>

<style scoped>
    @media only screen and (max-width: 767px) {
        h3 {
            font-size: 1.2rem;
        }
    }
</style>
