<template>
    <b-modal id="changePasswordModal" size="md"
             :hide-footer="true"
             :no-close-on-esc="true"
             :no-close-on-backdrop="true"
             class="p-0"
             @close="resetVariables"
    >
        <b-card no-body class="col-12 border-0 px-2 px-lg-4" v-if="userData">
            <h3 class="text-center text-white">Изменение пароля</h3>

            <b-tabs card class="change-pass-tab px-0">
                <b-tab title="Почта" :title-item-class="'changePasswordTabs'">

                    <Alert :message="emailSentResponse"></Alert>
                    <Alert :message="passChangeEmailResponse"></Alert>

                    <form v-if="!showSuccessMessageEmail" @submit.prevent="changePasswordWithEmail" class="mt-4">
                        <div class="form-group">
                            <input type="email" class="form-control input-bg input-bg-email"
                                   v-model="restoreEmailPayload.email"
                                   :disabled="userData.email_confirmed ===1 ? 'disabled' : false"
                                   placeholder="Ваша Почта">
                        </div>
                        <div class="form-group">
                            <div class="row mx-0">
                                <div class="col-4 col-sm-3 px-0">
                                    <Captcha ref="pass_change_email_captcha"
                                             :captcha_key="'pass_change_captcha'"></Captcha>
                                </div>
                                <div class="col-8 col-sm-9 pr-0">
                                    <input type="text" class="form-control input-bg input-bg-dot-auth"
                                           v-model="restoreEmailPayload.captcha">
                                </div>
                            </div>
                        </div>

                        <div class="form-group mt-4 text-center" v-if="!isEmailCodeSent">
                            <button class="btn red-btn px-5" name="submit" type="submit"
                                    @click.prevent="sendEmailCode">
                                Отправить письмо с кодом
                            </button>
                        </div>
                        <div class="row mx-0 mt-4" v-if="isEmailCodeSent">
                            <div class="form-group w-100">
                                <input class="form-control input-bg input-bg-dot-auth"
                                       v-model="restoreEmailPayload.email_sent_code"
                                       placeholder="Код из письма *">
                            </div>
                            <div class="form-group w-100">
                                <input type="password" class="form-control input-bg input-bg-pass"
                                       placeholder="Новый пароль *"
                                       v-model="restoreEmailPayload.new_password">
                            </div>
                            <div class="form-group w-100">
                                <input type="password" class="form-control input-bg input-bg-pass"
                                       placeholder="Новый пароль еще раз *"
                                       v-model="restoreEmailPayload.new_password_confirm">
                            </div>
                            <div class="form-group w-100 text-center">
                                <button class="btn red-btn px-5 mt-3" name="submit" type="submit"
                                        :disabled="passChangeEmailSubmitting">
                                    Изменить пароль
                                </button>
                            </div>
                        </div>
                    </form>

                    <div class="row mx-0 text-center my-5" v-else>
                        <span class="mx-auto text-white">
                            Отлично! Ваш пароль изменён.
                        </span>
                    </div>
                </b-tab>

                 <b-tab title="Телефон" v-if="siteSettings && siteSettings.phoneActionsMethod === 'call'"
                       :title-item-class="'changePasswordTabs'">

                    <Alert :message="callSentResponse"></Alert>
                    <Alert :message="passChangeCallResponse"></Alert>

                    <form v-if="!showSuccessMessageCall" @submit.prevent="changePasswordWithCall" class="mt-4">
                      <div class="form-group">
                        <input type="text" class="form-control input-bg input-bg-phone"
                               v-model="restoreCallPayload.phone"
                               :disabled="userData.phone_confirmed ===1 ? 'disabled' : false"
                               v-mask="['+#(###)-###-##-##', '+#(###)-###-##-###']" placeholder="Номер телефона">
                      </div>
                      <div class="form-group">
                        <div class="row mx-0">
                          <div class="col-4 col-sm-3 px-0">
                            <Captcha ref="password_change_sms" :captcha_key="'password_change_sms'"></Captcha>
                          </div>
                          <div class="col-8 col-sm-9 pr-0">
                            <input class="form-control input-bg input-bg-dot-auth" type="text"
                                   v-model="restoreCallPayload.captcha">
                          </div>
                        </div>
                      </div>

                      <div class="form-group text-center" v-if="!isPhoneCallSent">
                        <button class="btn red-btn px-5" name="submit" type="submit" @click.prevent="callForChangePass">
                          Звонить на номер
                        </button>
                      </div>
                      <div class="col-12 px-0 mt-4" v-if="isPhoneCallSent">
                        <div class="form-group">
                          <input type="text" class="form-control input-bg input-bg-dot-auth"
                                 placeholder="Последние 4 цифры номера  с которого поступил звонок"
                                 v-model="restoreCallPayload.phone_sent_code">
                        </div>
                        <div class="form-group">
                          <input type="password" class="form-control input-bg input-bg-pass"
                                 placeholder="Новый пароль *"
                                 v-model="restoreCallPayload.new_password">
                        </div>
                        <div class="form-group">
                          <input type="password" class="form-control input-bg input-bg-pass"
                                 placeholder="Повторите новый пароль *"
                                 v-model="restoreCallPayload.new_password_confirm">
                        </div>

                        <div class="form-group text-center mt-4">
                          <button class="btn red-btn px-5" name="submit" type="submit">
                            Изменить пароль
                          </button>
                        </div>
                      </div>
                    </form>

                    <div class="row mx-0 text-center my-5" v-else>
                            <span class="mx-auto text-white">
                                Отлично! Ваш пароль изменён.
                            </span>
                    </div>
                </b-tab>

                <b-tab title="Через SMS" v-else :title-item-class="'changePasswordTabs'">

                    <Alert :message="smsSentResponse"></Alert>
                    <Alert :message="passChangePhoneResponse"></Alert>

                    <form v-if="!showSuccessMessageSms" @submit.prevent="changePasswordWithSms" class="mt-4">
                        <div class="form-group">
                            <input type="text" class="form-control input-bg input-bg-phone"
                                   v-model="restoreSmsPayload.phone"
                                   :disabled="userData.phone_confirmed ===1 ? 'disabled' : false"
                                   v-mask="['+#(###)-###-##-##', '+#(###)-###-##-###']" placeholder="Номер телефона">
                        </div>
                        <div class="form-group">
                            <div class="row mx-0">
                                <div class="col-4 col-sm-3 px-0">
                                    <Captcha ref="password_change_sms" :captcha_key="'password_change_sms'"></Captcha>
                                </div>
                                <div class="col-8 col-sm-9 pr-0">
                                    <input class="form-control input-bg input-bg-dot-auth" type="text"
                                           v-model="restoreSmsPayload.captcha">
                                </div>
                            </div>
                        </div>

                        <div class="form-group text-center" v-if="!isPhoneCodeSent">
                            <button class="btn red-btn px-5" name="submit" type="submit" @click.prevent="sendSmsCode">
                                Отправить смс с кодом
                            </button>
                        </div>
                        <div class="col-12 px-0 mt-4" v-if="isPhoneCodeSent">
                            <div class="form-group">
                                <input type="text" class="form-control input-bg input-bg-dot-auth"
                                       placeholder="Код из смс *"
                                       v-model="restoreSmsPayload.phone_sent_code">
                            </div>
                            <div class="form-group">
                                <input type="password" class="form-control input-bg input-bg-pass"
                                       placeholder="Новый пароль *"
                                       v-model="restoreSmsPayload.new_password">
                            </div>
                            <div class="form-group">
                                <input type="password" class="form-control input-bg input-bg-pass"
                                       placeholder="Повторите новый пароль *"
                                       v-model="restoreSmsPayload.new_password_confirm">
                            </div>

                            <div class="form-group text-center mt-4">
                                <button class="btn red-btn px-5" name="submit" type="submit">
                                    Изменить пароль
                                </button>
                            </div>
                        </div>
                    </form>

                    <div class="row mx-0 text-center my-5" v-else>
                        <span class="mx-auto text-white">
                            Отлично! Ваш пароль изменён.
                        </span>
                    </div>
                </b-tab>
            </b-tabs>
        </b-card>
    </b-modal>
</template>

<script>
    import Alert from '@/components/Alert'
    import Captcha from '@/components/Captcha'

    export default {
        props: ['userData'],
        name: 'ChangePassword',
        computed: {
          siteSettings () {
            return this.$store.getters.getMainSettings
          }
        },
        data() {
            return {
                isEmailCodeSent: false,
                isPhoneCodeSent: false,
                isPhoneCallSent: false,
                emailSentResponse: null,
                smsSentResponse: null,
                callSentResponse: null,
                passChangeEmailResponse: null,
                passChangePhoneResponse: null,
                passChangeCallResponse: null,
                passChangeEmailSubmitting: false,
                restoreEmailPayload: {
                    email: '',
                    captcha: '',
                    email_sent_code: '',
                    new_password: '',
                    new_password_confirm: ''
                },
                restoreSmsPayload: {
                    phone: '',
                    captcha: '',
                    phone_sent_code: '',
                    new_password: '',
                    new_password_confirm: ''
                },
                restoreCallPayload: {
                      phone: '',
                      captcha: '',
                      phone_sent_code: '',
                      new_password: '',
                      new_password_confirm: ''
                  },
                showSuccessMessageEmail: false,
                showSuccessMessageSms: false,
                showSuccessMessageCall: false
            }
        },
        components: {
            Captcha,
            Alert
        },
        methods: {
            resetVariables() {
                this.emailSentResponse = null
                this.smsSentResponse = null
                this.passChangeEmailResponse = null
                this.passChangePhoneResponse = null

                this.isEmailCodeSent = false
                this.isPhoneCodeSent = false
                this.showSuccessMessageEmail = false
                this.showSuccessMessageSms = false

                this.restoreEmailPayload.captcha = ''
                this.restoreEmailPayload.email_sent_code = ''
                this.restoreEmailPayload.new_password = ''
                this.restoreEmailPayload.new_password_confirm = ''

                this.restoreSmsPayload.captcha = ''
                this.restoreSmsPayload.phone_sent_code = ''
                this.restoreSmsPayload.new_password = ''
                this.restoreSmsPayload.new_password_confirm = ''
            },
            sendEmailCode() {
                if (!this.userData.email.length || !this.restoreEmailPayload.captcha.length) {
                    this.emailSentResponse = {status: 'error', msg: 'Заполните поля почты и капчи'}
                    return 0
                }
                this.emailSentResponse = {status: 'loading'}
                this.axios.post(
                    this.$_config.passwordChangeEmailPath,
                    `email=${this.restoreEmailPayload.email ? this.restoreEmailPayload.email : this.userData.email}&captcha=${this.restoreEmailPayload.captcha}`,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(({data}) => {
                    this.emailSentResponse = data
                    if (data.status === 'ok') {
                        this.isEmailCodeSent = true
                    }
                    this.restoreEmailPayload.captcha = ''
                })
                this.$refs.pass_change_email_captcha.updateCaptcha()
            },
            sendSmsCode() {
                if (!this.restoreSmsPayload.phone.length || !this.restoreSmsPayload.captcha.length) {
                    this.smsSentResponse = {status: 'error', msg: 'Заполните все поля'}
                    return 0
                }
                this.smsSentResponse = {status: 'loading'}
                this.axios.post(
                    this.$_config.passwordChangeSmsPath,
                    `phone=${this.restoreSmsPayload.phone}&captcha=${this.restoreSmsPayload.captcha}`,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(({data}) => {
                    if (data.status === 'ok') {
                        this.isPhoneCodeSent = true
                    }
                    this.smsSentResponse = data
                    this.restoreSmsPayload.captcha = ''
                }).catch(err => {
                    console.log(err)
                })

                setTimeout(() => {
                    this.$refs.password_change_sms.updateCaptcha()
                }, 1000)
            },
            callForChangePass() {
                if (!this.restoreCallPayload.phone.length || !this.restoreCallPayload.captcha.length) {
                    this.callSentResponse = {status: 'error', msg: 'Заполните все поля'}
                    return 0
                }
                this.callSentResponse = {status: 'loading'}
                this.axios.post(
                    this.$_config.passwordChangeCallPath,
                    `phone=${this.restoreCallPayload.phone}&captcha=${this.restoreCallPayload.captcha}`,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(({data}) => {
                    if (data.status === 'ok') {
                        this.isPhoneCallSent = true
                    }
                    this.callSentResponse = data
                    this.restoreCallPayload.captcha = ''
                }).catch(err => {
                    console.log(err)
                })

                setTimeout(() => {
                    this.$refs.password_change_sms.updateCaptcha()
                }, 1000)
            },
            changePasswordWithEmail() {
                this.passChangeEmailResponse = {status: 'loading'}
                this.passChangeEmailSubmitting = true
                this.emailSentResponse = null
                let sendPayload = {
                    new_password: this.restoreEmailPayload.new_password,
                    new_password_confirm: this.restoreEmailPayload.new_password_confirm,
                    restore_code: this.restoreEmailPayload.email_sent_code,
                    password_change_captcha: this.restoreEmailPayload.captcha
                }
                let queryString = this.$_config.makeQueryStringFromObject(sendPayload)
                this.axios.post(
                    `${this.$_config.passwordChangeRequests.mail}`, queryString, {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(({data}) => {
                    this.passChangeEmailResponse = data
                    if (data.status === 'ok') {
                        this.passChangeEmailResponse = null
                        this.showSuccessMessageEmail = true
                        setTimeout(() => {
                            this.resetVariables()
                            this.$root.$emit('bv::hide::modal', 'changePasswordModal')
                        }, 7000)
                    }
                }).catch(() => {
                    this.passChangeEmailResponse = {
                        status: 'error',
                        msg: 'Ошибка при отправке запроса, попробуйте снова.'
                    }
                })

                this.restoreEmailPayload.captcha = ''
                this.passChangeEmailSubmitting = false
                this.$refs.pass_change_email_captcha.updateCaptcha()
            },
            changePasswordWithSms() {
                this.passChangePhoneResponse = {status: 'loading'}
                this.smsSentResponse = null

                let sendPayload = {
                    new_password: this.restoreSmsPayload.new_password,
                    new_password_confirm: this.restoreSmsPayload.new_password_confirm,
                    restore_code: this.restoreSmsPayload.phone_sent_code,
                    password_change_captcha: this.restoreSmsPayload.captcha
                }

                let queryString = this.$_config.makeQueryStringFromObject(sendPayload)
                this.axios.post(
                    `${this.$_config.passwordChangeRequests.sms}`, queryString, {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(({data}) => {
                    this.passChangePhoneResponse = data
                    if (data.status === 'ok') {
                        this.passChangePhoneResponse = null
                        this.showSuccessMessageSms = true
                        this.isPhoneCodeSent = true
                        setTimeout(() => {
                            this.resetVariables()
                            this.$root.$emit('bv::hide::modal', 'changePasswordModal')
                        }, 7000)
                    }
                }).catch(() => {
                    this.passChangePhoneResponse = {
                        status: 'error',
                        msg: 'Ошибка при отправке запроса, попробуйте снова.'
                    }
                })
                this.restoreSmsPayload.captcha = ''
                this.$refs.password_change_sms.updateCaptcha()
            },
            changePasswordWithCall() {
                console.log("ddd")
                this.passChangeCallResponse = {status: 'loading'}
                this.callSentResponse = null

                let sendPayload = {
                    new_password: this.restoreCallPayload.new_password,
                    new_password_confirm: this.restoreCallPayload.new_password_confirm,
                    restore_code: this.restoreCallPayload.phone_sent_code,
                    password_change_captcha: this.restoreCallPayload.captcha
                }

                let queryString = this.$_config.makeQueryStringFromObject(sendPayload)
                this.axios.post(
                    `${this.$_config.passwordChangeRequests.call}`, queryString, {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(({data}) => {
                    this.passChangeCallResponse = data
                    if (data.status === 'ok') {
                        this.passChangeCallResponse = null
                        this.showSuccessMessageCall = true
                        this.isPhoneCallSent = true
                        setTimeout(() => {
                            this.resetVariables()
                            this.$root.$emit('bv::hide::modal', 'changePasswordModal')
                        }, 7000)
                    }
                }).catch(() => {
                    this.passChangeCallResponse = {
                        status: 'error',
                        msg: 'Ошибка при отправке запроса, попробуйте снова.'
                    }
                })
                this.restoreCallPayload.captcha = ''
                this.$refs.password_change_sms.updateCaptcha()
            },
        },
        mounted() {
            if (this.userData) {
                this.restoreEmailPayload.email = this.userData.email
                this.restoreSmsPayload.phone = this.userData.phone
                this.restoreCallPayload.phone = this.userData.phone
            }
        }
    }
</script>

<style>
    .change-pass-tab .card-header {
        background-color: transparent;
        border: none;
        padding: 0;
    }

    .change-pass-tab .nav-tabs .nav-item {
        border: none !important;
        margin: 0 0 15px 0;
    }

    .change-pass-tab .nav-tabs .nav-item .nav-link {
        color: white !important;
        background-color: transparent;
        font-weight: bold;
        border: none;
        padding: 20px 0 10px 0 !important;
        margin-right: 35px;
        border-bottom: 1px solid white;
        outline: none;
    }

    .change-pass-tab .nav-tabs .nav-item .nav-link.active {
        color: #a4252e !important;
        border-bottom: 1px solid #a4252e;
    }

    .change-pass-tab .card-body {
        padding: 0;
    }

    #changePasswordModal .modal-body {
        padding-left: 0;
        padding-right: 0;
    }

    #changePasswordModal .card {
        background-color: transparent !important;
    }

    #changePasswordModal .card-header-tabs {
        justify-content: center;
        margin-left: 0;
    }
</style>
