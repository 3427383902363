<template>
    <div class="profile-container main-bg-light row w-100 mx-0 py-5">
        <div class="col-11 col-lg-8 px-0 mx-auto pt-1 pt-md-5 pt-lg-3 pt-xl-0" v-if="userData && userDataFetched">
            <h2 class="text-white text-center">Профиль</h2>
            <h6 class="text-white my-2 text-center">Здравствуйте {{userData.login}}</h6>

            <b-tabs class="tab profile-tab mt-4 row justify-content-between mx-auto">
                <b-tab class="tab-item" :active="activeTab==='account'"
                       @click="onTabChange('account')">
                    <template v-slot:title>
                        <img class="mr-1 mr-lg-2" :src="profileIcon" alt="profile" width="20px">
                        <span>Профиль</span>
                    </template>
                    <account></account>
                </b-tab>
                <b-tab class="tab-item"  @click="onTabChange('messages')" :active="activeTab==='messages'">
                    <template v-slot:title>
                        <img class="mr-1 mr-lg-2" :src="messageIcon" alt="messages" width="20px">
                        <span>Письма</span>
                        <div class="orange-bg" v-if="unreadMessages">
                            <span class="text-white fs-14"> {{unreadMessages}} </span>
                        </div>
                    </template>
                    <messages></messages>
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
    import Messages from "@/components/user/profile/Messages"
    import Account from "@/components/user/profile/Account"
    export default {
        name: 'Profile',
        props: ['placeName'],
        data() {
            return {
                profileIcon: require('@/../public/images/icons/user.png'),
                messageIcon: require('@/../public/images/icons/message.png'),
                activeTab: 'account'
            }
        },
        components: {
            Messages,
            Account
        },
        created() {
            this.$store.dispatch('fetchUserIdentityData')
        },
        computed: {
            siteSettings () {
              return this.$store.getters.getMainSettings
            },
            userData() {
                return this.$store.getters['userData']
            },
            userDataFetched() {
                return this.$store.getters.userDataFetched
            },
            unreadMessages() {
                let msgs = this.$store.getters['getUnreadMessages']
                if (msgs) {
                    return msgs.length
                }
                return null
            }
        },
        updated() {
            this.identityData = this.$store.getters.identityData
        },
        methods :{
          onTabChange (toRoute) {
            if (this.activeTab !== toRoute) {
              this.$router.push({ name: 'profile', params: {placeName : toRoute} })
            }
          }
        },
        mounted() {
            if (!this.userData) {
              return this.$router.push({path: '/'})
            }

            if (typeof this.placeName !== "undefined") {
              this.activeTab = this.placeName
            } else if (this.$route.params && this.$route.params.placeName) {
              this.activeTab = this.$route.params.placeName
              this.$router.push({ name: 'profile', params: {placeName : this.$route.params.placeName} })
            } else {
              this.$router.push({ name: 'profile', params: {placeName : 'account'} })
            }
        },
        watch: {
          $route (to) {
            if (to && to.params && to.params.placeName) {
                this.activeTab = to.params.placeName
            }
          }
        }
    }
</script>

<style>
    .profile-tab .nav-tabs {
        border: none !important;
        font-weight: bold;
        margin: auto;
    }
    .profile-tab>div{
      margin: auto;
    }
    .profile-tab .nav-tabs .nav-item .nav-link {
        border: none;
        outline: none;
        color: white;
        margin-right: 50px;
        padding-left: 0 !important;
        position: relative;
        border-bottom: 2px solid white !important;
    }

    .profile-tab .nav-tabs .nav-item .nav-link.active {
        color: #a4252e;
        background-color: transparent;
        border-bottom: 2px solid #a4252e !important;
    }

    @media only screen and (max-width: 767px) {
        .profile-tab .nav-tabs .nav-item .nav-link {
            margin-right: 30px;
        }

        .profile-tab .nav-tabs {
            justify-content: center;
        }
    }
</style>

<style scoped>
    .profile-container{
        background-image: url("../../../public/images/bg.png");
        background-size: cover;
    }

    .orange-bg {
        background-color: #a4252e;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        text-align: center;
        position: absolute;
        right: 0;
        top: -10px;
    }

</style>
