<template>
    <div class="col-12 px-0">
        <div class="winners-list-container mb-3 mb-xl-0" v-if="winners">
            <div class="winners-title row w-100 mx-0">
                <img :src="starIocn" alt="SegaSlot - Winers" class="h-100 my-3">
                <h3 class="title text-white mx-xl-auto ml-3 ml-xl-0 my-2">Победители</h3>
            </div>
            <div class="col-12 winners-slider">
                <b-carousel :interval="5000" indicators class="w-100">
                    <b-carousel-slide v-for="(winner_group, idx) in winners" :key="idx">
                        <template v-slot:img>
                            <div v-for="(winner, ind) in winner_group" :key="ind"
                                 class="mx-0 float-left col-12 col-sm-6 col-md-4 col-xl-12 px-xl-0">
                                <div class="row mx-0 py-2 py-md-3 py-xl-2">
                                    <div class="col-5 col-xl-4 pl-0">
                                        <span class="amount font-weight-bold">{{winner.login}}</span>
                                    </div>
                                    <div class="col-7 col-xl-8 pr-0 text-right text-sm-left text-xl-right">
                                        <span class="text-white font-weight-bold">{{winner.win}} Руб</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </b-carousel-slide>
                </b-carousel>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                starIocn: require('@/../public/images/icons/winner-star.png')
            }
        },
        computed: {
            winners() {
                let winners = this.$store.getters['getWinnersList']
                if (!winners) {
                    return
                }
                const length = winners.length
                let winners_array = []

                for (let step = 0; step < Math.ceil(length / 9); step++) {
                    winners_array[step] = winners.slice(step * 9, (step + 1) * 9)
                }
                return winners_array
            }
        },
        // mounted() {
        //     this.$store.dispatch('fetchWinnersList')
        // }
    }
</script>

<style>
    .winners-slider .carousel-indicators {
        border-right: 15px solid transparent;
        border-left: 15px solid transparent;
        border-bottom: 30px solid #002262;
        margin: 0 auto;
        bottom: -40px;
        width: 90%;
    }

    .winners-slider .carousel-indicators li.active {
        background-color: #e5001b !important;
    }

    .winners-slider .carousel-indicators li {
        background-color: white;
        margin-bottom: -30px;
        border-radius: 50%;
        outline: none;
        height: 12px;
        width: 12px;
        opacity: 1;
        left: 5%;
    }
</style>

<style scoped>
    .winners-list-container {
        background-color: #0344bd;
        border-radius: 5px;
        min-height: 453px;
    }

    .carousel-item {
        min-height: 360px;
    }

    .amount {
        color: #01276f;
    }


    @media (min-width: 576px) and (max-width: 1199px) {
        .carousel-item {
            min-height: 200px;
        }

        .winners-list-container {
            min-height: 290px;
        }
    }

    @media (min-width: 1200px) and (max-width: 1225px) {
        h3 {
            font-size: 1.60rem;
        }
    }
</style>
