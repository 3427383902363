<template>
    <div class="home-container">
        <slider></slider>
        <div class="main-content col-12 px-0">
            <game-list></game-list>
        </div>
    </div>
</template>

<script>
    import Slider from '@/components/home/Slider'
    import GameList from '@/components/games/GameList'

    export default {
        name: 'Home',
        components: {
            Slider,
            GameList,
        }
    }
</script>
