<template>
    <b-modal id="flashMessagesModal"
             v-model="canShowMessages"
             size="lg"
             :hide-header="true"
             :hide-footer="true"
             :no-close-on-esc="true"
             :no-close-on-backdrop="true"
    >
        <h3 class="text-white text-center my-4">Системное сообщение</h3>
        <div class="row mx-0 message-text-container text-white px-3 py-3 mb-1"
             v-for="(msg, idx) in flashMessages" :key="idx">
            <span class="text-white" v-html="msg">  </span>
        </div>

        <div class="col-12 px-0 text-center">
            <div class="d-inline-block">
                <button class="btn markAsReadBtn text-orange font-weight-bold" @click.prevent="unsetFlashMessages">
                    Отметить как прочитанное
                </button>
            </div>
        </div>

    </b-modal>
</template>

<script>
export default {
    name: 'FlashMessages',
    computed: {
        flashMessages() {
            return this.$store.getters.getFlashMessages
        },
        canShowMessages() {
            if (this.flashMessages) {
                return true
            }
            return false
        }
    },
    methods: {
        unsetFlashMessages() {
            this.$store.dispatch('unsetFlashMessages')
            return this.$root.$emit('bv::hide::modal', 'flashMessagesModal')
        }
    },
}
</script>

<style scoped>
    .markAsReadBtn {
        box-shadow: none !important;
    }

    .markAsReadBtn:hover {
        color: #ffffff !important;
    }
</style>
