import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import Socket from '@/socket'
import BootstrapVue from 'bootstrap-vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Config from '@/config'
import VueTheMask from 'vue-the-mask'
import VueLazyload from 'vue-lazyload'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/../public/styles/fontawesome.css'
import '@/../public/styles/main.css'
import '@/../public/styles/claim.css'

const Chat = require('@/common/chat.js')
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(Config)
Vue.use(VueTheMask)
Vue.use(VueLazyload)

axios.defaults.withCredentials = true
Vue.config.productionTip = false

store.dispatch('fetchUserData').then(async (resp) => {
    Vue.use(Socket, store)
    if (resp.login) {
        Chat({
            login: resp.login + ', ' + resp.id + ', ' + 'Segaslot',
            email: resp.email
        })
    } else {
        Chat()
    }

    await store.dispatch('fetchApiData')
    new Vue({
        el: '#app',
        router,
        store,
        template: '<App/>',
        components: {App},
        render: h => h(App),
        methods: {
            mountExternalSources() {
                [{type: 'js', src: `${this.$_config.baseUrl}/static/public/js/claim/claim.js`},
                    {type: 'css', src: `${this.$_config.baseUrl}/static/public/js/claim/main.css`}
                ].forEach((source) => {
                    let elem = null
                    if (source.type === 'js') {
                        elem = document.createElement('script')
                        elem.src = source.src
                    } else if (source.type === 'css') {
                        elem = document.createElement('link')
                        elem.setAttribute('rel', 'stylesheet')
                        elem.href = source.src
                    }
                    document.head.appendChild(elem)
                })
            }
        },
        async mounted() {
            this.mountExternalSources()
        }
    })
})
