/*eslint no-undef: "off"*/

const Chat = (params = {}) => {
  // check if metrics already enabled
  let enabled = window.chatEnabled
  if (enabled) {
    return ''
  }
  window.chatEnabled = 1
  window.Tawk_API = window.Tawk_API || {}

  if (params.login) {
    Tawk_API.visitor = {
      name: params.login,
      email: params.email,
    };
  }

  let chatScript = document.createElement('script')
  chatScript.src = "https://embed.tawk.to/5cafd4f9c1fe2560f3fe71bb/default"
  chatScript.async = true
  chatScript.charset = 'UTF-8'
  chatScript.setAttribute('crossorigin', '*');

  document.body.appendChild(chatScript)
}

module.exports = Chat